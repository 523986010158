import { Grid, Typography } from '@material-ui/core'
import React from 'react'
import {URL_API} from '../../config'
import {useTranslation} from "react-i18next";
import './styles.css'

export const Pilier = ({pilier}) => {
    const [t, translate] = useTranslation('common');

    return (
        <Grid item xs={8} sm={8} md={6} lg={3} className="div-grid" >
            <Grid container
                direction="column"
                justify="space-around"
                alignItems="center" className="box">
                <div className="marge-bottom pilier-icon">
                    <img alt="tasmeem icon" src={`${URL_API}${pilier.icon?.url}`} />
                </div>
                <div className="marge-bottom">
                    <Typography variant="h5">
                        {translate.language === 'fr'?pilier.title_fr:pilier.title_en}
                    </Typography>
                </div>
                <div className="marge-bottom" style={{flexGrow: 1}}>
                    <Typography component="p">
                        {translate.language === 'fr'?pilier.description_fr:pilier.description_en}
                    </Typography>
                </div>
            </Grid>
        </Grid>
    )
}
