import React from 'react'
import {DiscussionEmbed} from "disqus-react"

export const DisqusComments = ({ post }) => {
    const disqusShortname = "tasmeem"  
    const disqusConfig = {
        url: "https://tasmeem.ma/blog/2",
        identifier: 2, // Single post id
        title: 'test-2' // Single post title
      }

  return (
    <div>
      <DiscussionEmbed
        shortname={disqusShortname}
        config={disqusConfig}
      />
    </div>
  )
}
