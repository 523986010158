import { Grid } from '@material-ui/core'
import React from 'react'
import {Pilier} from "./Pilier"
import {TitleheaderLine} from '../texte/TitleheaderLine'
import {useTranslation} from "react-i18next";
import './styles.css'

export const Piliers = ({listPiliers}) => {
    const [t] = useTranslation('common');
    return (
        <Grid container justify="center" className="expertise-bloc" style={{paddingBottom:'0px', paddingTop:"0px"}}>
            <Grid container justify="center">
                    <TitleheaderLine info={{title:t('service.pilier_title'), color:"jaune"}} textColor="#fff" />
                </Grid>
            <Grid item xs={10}>
                <Grid container spacing={4} justify="center">
                    {
                        listPiliers.map(pilier =>(
                            <Pilier pilier={pilier} />
                        ))
                    }
                </Grid>
            </Grid>
        </Grid>
        
    )
}
