import React from 'react'
import {Header} from '../components/header/Header'
import {Piliers} from '../components/piliers/Piliers'
import {findoneService} from '../api/services'
import {Quiz} from '../components/quiz/Quiz'
import { useParams, useHistory } from 'react-router-dom'
import {URL_API} from '../config'
import {useTranslation} from "react-i18next";
import { Loader } from '../components/Loader'
import { MetaTags } from '../components/MetaTags'
import { serviceData } from '../data/service'

export const Service = () => {
    const [service, setService] = React.useState()
    const [loading, setLoading] = React.useState(true)
    const [t, translate] = useTranslation('common');
    let { nom } = useParams();
    const history = useHistory()  

    React.useEffect(() => {
        setLoading(true)
       findoneService(nom)
       .then(response =>{
        setService(response[0])
        setLoading(false)
       })
       .catch(error =>{
           history.push(`/404`)
       })
    },[nom] )

    return !loading?(
        <React.Fragment>
        <MetaTags
        meta={
            {
                title:translate.language === 'fr'?serviceData[nom].title_fr:serviceData[nom].title_en, 
                description:translate.language === 'fr'?serviceData[nom].description_fr: serviceData[nom].description_en,
                url:window.location.href,
                image:"https://tasmeem.ma/assets/logo/logo.png",
            }
        } />
        {
            service?(
                <React.Fragment>
                    <Header 
                    img={`${URL_API}${service.image?service.image.url:null}`} 
                    title={translate.language === 'fr'?service.title_fr:service.title_en} 
                    paragraph={translate.language === 'fr'?service.description_fr:service.description_en} 
                    reverse 
                    />
                    <Piliers listPiliers={service.piliers} />
                    
                    <Quiz />        
                </React.Fragment>
        ):null
        }
        </React.Fragment>
    ):(
        <React.Fragment>
        <MetaTags
        meta={
            {
                title:translate.language === 'fr'?serviceData[nom].title_fr:serviceData[nom].title_en, 
                description:translate.language === 'fr'?serviceData[nom].description_fr: serviceData[nom].description_en,
                url:window.location.href,
                image:"https://tasmeem.ma/assets/logo/logo.png",
            }
        } />
        <Loader />
        </React.Fragment>
    )
    
}
