import { Grid, Typography } from '@material-ui/core'
import React from 'react'
import {TitleheaderLine} from '../texte'
import {Bouton} from '../bouton'
import {useTranslation} from "react-i18next";

export const Quiz = () => {
    const [t, translate] = useTranslation('common');
    return (
        <Grid container className="bloc-padding bloc-jaune" justify="center">
            <Grid item xs={7}>
                <TitleheaderLine info={{title:t('quiz_section.title'), color:"bleu"}} />
                <Typography variant="h5" component="p" className="title" align="center">
                    {`${t('quiz_section.description')} `}<span style={{fontWeight:"bold"}}>{t('quiz_section.description_gras')}</span>
                </Typography>
                <Bouton txt={t('quiz_section.bouton')} path="/quiz" position="center" color={true} />
            </Grid>
        </Grid>
    )
}
