import React from 'react'
import './style.css'
import { makeStyles } from '@material-ui/core/styles';
import { Button, Checkbox, FormControl, FormControlLabel, Grid, Radio, RadioGroup, Slider, TextField, Typography } from '@material-ui/core';
import emailjs from 'emailjs-com';
import {useTranslation} from "react-i18next";
import { withStyles } from '@material-ui/core/styles';
import {CheckBoxOutlineBlank} from '@material-ui/icons';
import { useSnackbar } from 'notistack'

const useStyles = makeStyles({
    root: {
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
          borderColor: "#FFDA25"
        }
      },
      marge:{
        marginTop: '15px !important'
      },
    step:{
        height: "100%",
        backgroundColor: "#FFDA25",
        borderRadius: "25px",
    }
  });

  const CustumCheckbox = withStyles({
    root: {
      color: "#243C39",
      '&$checked': {
        color: "#FFDA25",
      },
    },
    checked: {},
  })((props) => <Checkbox color="default" icon={<CheckBoxOutlineBlank />} {...props} />);

  const CustumSlider = withStyles({
    root: {
      color: '#243C39',
      height: 4,
    },
    thumb: {
      height: 16,
      width: 16,
      backgroundColor: '#FFDA25',
      border: '2px solid currentColor',
      marginTop: -6,
      marginLeft: -8,
      '&:focus, &:hover, &$active': {
        boxShadow: 'inherit',
      },
    },
    active: {},
    valueLabel: {
      left: 'calc(-50% - 4px)',
    },
    track: {
      height: 4,
      borderRadius: 4,
    },
    rail: {
      height: 4,
      borderRadius: 4,
    },
  })(Slider);

const CustumRadio = withStyles({
    root: {
      
      '&$checked': {
        color: "#FFDA25",
      },
    },
    checked: {},
  })((props) => <Radio color="default" {...props} />);

export const BoxQuiz = () => {
    const classes = useStyles();
    const [question, setQuestion] = React.useState(0)
    const [t, translate] = useTranslation('common');

    const [form, setForm] = React.useState({
        nom:"",
        tel:"",
        email:"",
        message:"",
        communication:"Lancer un nouveau produit/service",
        'audience':{
            'jeune-mature':20,
            'feminine-masculine':25,
            'national-international':30,
        },
        
        'opinion-service':"Produit/service inconnu",
        'public-fasse':"Sensibilisation",
        'quoi-transmettre':"",
        'personnaliter':{
            'luxueux-economique':20,
            'classique-moderne':30,
            'ludique-serieux':40,
            'discret-voyant':30,
            'simple-complexe':20,
        },
        'livrables':{
            'identite-visuelle':false,
            'campagne-publicitaire':false,
            'contenu-digital':false,
            'site-web':false,
            'application':false,
            'contenu-editorial':false,
            'evenementiel':false,
            'audiovisuel':false,
            'autre':false,
        },
        'autreText':"",
        'materiaux':"Oui",
        'campagne':"Oui",
        'equipe-creation-utils':"",
        formType:"d’estimation de besoins"
    })

    const [quiz, setQuiz] = React.useState([
        {
            type:"input",
            question_fr:"Quel problème le service ou produit résout-il ?",
            question_en:"What problem does the service or product solve?",
            contenu:[
                {
                    type:'texArea',
                    label_fr:"Message",
                    label_en:"Message",
                    value:"",
                    name:"message"
                }
            ]
        },
        {
            type:"radio",
            question_fr:"Quel est le but de la communication que notre agence créera pour le produit/service ? ",
            question_en:"What is the purpose of the communication that our agency will create for the product / service? ",
            contenu_fr:["Lancer un nouveau produit/service","Lancer un sous-service/sous-produit","Retravailler l’image d’un service/produit","Publicité ou campagne pour un service/produit"],
            contenu_en:["Launch a new product / service","Start a sub-service / by-product","Rework the image of a service / product","Advertising or campaign for a service / product"],
            value:"Lancer un nouveau produit/service",
            name:"communication"
        },
        {
            type:"slider",
            question_fr:"Quelle est l'audience du produit/service ?",
            question_en:"What is the audience for the product / service ?",
            name:'audience',
            contenu_fr:[
                {
                    marks:[
                        {
                            value: 0,
                            label: 'Jeune',
                            left:"100%"
                        },
                        {
                            value: 100,
                            label: 'Mature',
                        },
                    ],
                    value:20,
                    name:"jeune-mature"
                },
                {
                    marks:[
                        {
                            value: 0,
                            label: 'Féminine',
                        },
                        {
                            value: 100,
                            label: 'Masculine',
                        },
                    ],
                    value:25,
                    name:"feminine-masculine"
                },
                {
                    marks:[
                        {
                            value: 0,
                            label: 'National',
                        },
                        {
                            value: 100,
                            label: 'International',
                        },
                    ],
                    value:30,
                    name:"national-international"
                }
            ],
            contenu_en:[
                {
                    marks:[
                        {
                            value: 0,
                            label: 'Young',
                        },
                        {
                            value: 100,
                            label: 'Mature',
                        },
                    ],
                    value:20,
                    name:"jeune-mature"
                },
                {
                    marks:[
                        {
                            value: 0,
                            label: 'Feminine',
                        },
                        {
                            value: 100,
                            label: 'Male',
                        },
                    ],
                    value:25,
                    name:"feminine-masculine"
                },
                {
                    marks:[
                        {
                            value: 0,
                            label: 'National',
                        },
                        {
                            value: 100,
                            label: 'International',
                        },
                    ],
                    value:30,
                    name:"national-international"
                }
            ]
        },
        {
            type:"radio",
            question_fr:"Quelle est l'opinion actuelle du public à propos du service ?",
            question_en:"What is the current public opinion about the service ?",
            contenu_fr:["Produit/service inconnu","Produit/service connu","Réticence"],
            contenu_en:["Unknown product / service","Known product / service","Reluctance"],
            value:"Produit/service inconnu",
            name:"opinion-service"
        },
        {
            type:"radio",
            question_fr:"Que voulons-nous que le public fasse ?",
            question_en:"What do we want the public to do?",
            contenu_fr:["Sensibilisation","Fidélisation","Acquisition","Changement d'opinion"],
            contenu_en:["Sensitization","Loyalty","Acquisition","Change of opinion"],
            value:"Sensibilisation",
            name:"public-fasse"
        },
        {
            type:"input",
            question_fr:"Quelle est l'idée la plus simple que nous devons transmettre ?",
            question_en:"What is the simplest idea that we have to convey ?",
            contenu:[
                {
                    type:'textArea',
                    label:"",
                    label_en:"",
                    value:"",
                    name:"quoi-transmettre"
                }
            ]
        },
        {
            type:"slider",
            question_fr:"Quelle est la personnalité que nous voulons véhiculer ?",
            question_en:"What is the personality that we want to convey ?",
            name:"personnaliter",
            contenu_fr:[
                {
                    marks:[
                        {
                            value: 0,
                            label: 'Luxueux',
                        },
                        {
                            value: 100,
                            label: 'Economique',
                        },
                    ],
                    value:20,
                    name:"luxueux-economique"
                },
                {
                    marks:[
                        {
                            value: 0,
                            label: 'Classique',
                        },
                        {
                            value: 100,
                            label: 'Moderne',
                        },
                    ],
                    value:30,
                    name:'classique-moderne'
                },
                {
                    marks:[
                        {
                            value: 0,
                            label: 'Ludique',
                        },
                        {
                            value: 100,
                            label: 'Sérieux',
                        },
                    ],
                    value:40,
                    name:"ludique-serieux"
                },
                {
                    marks:[
                        {
                            value: 0,
                            label: 'Discret',
                        },
                        {
                            value: 100,
                            label: 'Voyant',
                        },
                    ],
                    value:30,
                    name:"discret-voyant"
                },
                {
                    marks:[
                        {
                            value: 0,
                            label: 'Simple',
                        },
                        {
                            value: 100,
                            label: 'Complexe',
                        },
                    ],
                    value:20,
                    name:"simple-complexe"
                }
            ],
            contenu_en:[
                {
                    marks:[
                        {
                            value: 0,
                            label: 'Luxurious',
                        },
                        {
                            value: 100,
                            label: 'Economic',
                        },
                    ],
                    value:20,
                    name:"luxueux-economique"
                },
                {
                    marks:[
                        {
                            value: 0,
                            label: 'Classic',
                        },
                        {
                            value: 100,
                            label: 'Modern',
                        },
                    ],
                    value:30,
                    name:'classique-moderne'
                },
                {
                    marks:[
                        {
                            value: 0,
                            label: 'Playful',
                        },
                        {
                            value: 100,
                            label: 'Serious',
                        },
                    ],
                    value:40,
                    name:"ludique-serieux"
                },
                {
                    marks:[
                        {
                            value: 0,
                            label: 'Discreet',
                        },
                        {
                            value: 100,
                            label: 'Seeing',
                        },
                    ],
                    value:30,
                    name:"discret-voyant"
                },
                {
                    marks:[
                        {
                            value: 0,
                            label: 'Simple',
                        },
                        {
                            value: 100,
                            label: 'Complex',
                        },
                    ],
                    value:20,
                    name:"simple-complexe"
                }
            ]  
        },
        {
            type:"checkbox",
            question_fr:"Quels sont les livrables ?",
            question_en:"What are the deliverables ?",
            contenu:[
                {
                    label_fr:"Identité visuelle & déclinaisons",
                    label_en:"Visual identity & variations",
                    name:"identite-visuelle",
                    value : false
                },
                {
                    label_fr:"Campagne publicitaire",
                    label_en:"Advertising campaign",
                    name:"campagne-publicitaire",
                    value : false
                },
                {
                    label_fr:"Contenu Digital",
                    label_en:"Digital content",
                    name:"contenu-digital",
                    value : false
                },
                {
                    label_fr:"Site web",
                    label_en:"Website",
                    name:"site-web",
                    value : false
                },
                {
                    label_fr:"Application",
                    label_en:"Application",
                    name:"application",
                    value : false
                },
                {
                    label_fr:"Contenu éditorial",
                    label_en:"Editorial content",
                    name:"contenu-editorial",
                    value : false
                },
                {
                    label_fr:"Evenementiel",
                    label_en:"Event",
                    name:"evenementiel",
                    value : false
                },
                {
                    label_fr:"Audiovisuel",
                    label_en:"Audio-visual",
                    name:"audiovisuel",
                    value : false
                },
                {
                    label_fr:"Autre",
                    label_en:"Other",
                    name:"autre",
                    value : false
                },
            ],
            autre:{
                value:"",
                name:"autre"
            }
        },
        {
            type:"radio",
            question_fr:"Y a-t-il des matériaux sources ? ",
            question_en:"Are there any source materials ?",
            contenu_fr:[
                "Oui",
                "Non"
            ],
            contenu_en:[
                "Yes",
                "No"
            ],
            value:"Oui",
            name:"materiaux"
        },
        {
            type:"radio",
            question_fr:"Ce contenu fait-il partie d'une campagne plus large ? ",
            question_en:"Is this content part of a larger campaign ?",
            contenu_fr:[
                "Oui",
                "Non"
            ],
            contenu_en:[
                "Oui",
                "Non"
            ],
            value:"Oui",
            name:"campagne"
        },
        {
            type:"input",
            question_fr:"Quelles informations supplémentaires seraient utiles à l'équipe de création ?",
            question_en:"What additional information would be useful to the creative team ?",
            contenu:[
                {
                    type:'textArea',
                    label_fr:"",
                    label_en:"",
                    value:"",
                    name:"equipe-creation-utils"
                }
            ]
        },
        {
            type:"input",
            question_fr:"Merci d’avoir pris le temps de répondre aux questions. Pour que nous puissions vous contacter, veuillez communiquer vos informations ci-dessous ",
            question_en:"Thank you for taking the time to answer the questions. In order for us to contact you, please provide your information below", 
            contenu:[
                {
                    type:'input',
                    label_fr:"Nom",
                    label_en:"Name",
                    value:"",
                    name:"nom",
                    require:true
                },
                {
                    type:'input',
                    label_fr:"Téléphone",
                    label_en:"Phone",
                    value:"",
                    name:"tel",
                    require:true,
                    typet:"tel"
                },
                {
                    type:'input',
                    label_fr:"E-mail",
                    label_en:"E-mail",
                    value:"",
                    name:"email",
                    require:true,
                    typet:"email"
                }
            ]
        }
    ])
    const { enqueueSnackbar } = useSnackbar()

    const handleSubmit = (event) => {
        event.preventDefault();

        let copy = {... form}
        copy['livrable'] = ''
        for (const [key, value] of Object.entries(form.livrables)) {
            if(key !== "autre"){
                if(value){
                    copy['livrable'] = copy['livrable'] + `<p>${key}</p>`
                }
            } else {
                if(value){
                    copy['livrable'] = copy['livrable'] + `<p style="font-size: 16px;">${form['autreText']}</p>`
                }
            }
            
          }

        copy['html-content'] = `
        <table style="border-collapse: collapse; width: 80%; border-color: #dba720; margin-left: auto; margin-right: auto;" border="2">
            <tbody>
                <tr>
                    <td style="width: 49.7516%;"><h2>Nom</h2></td>
                    <td style="width: 49.7527%;">${copy.nom}</td>
                </tr>
                <tr>
                    <td style="width: 49.7516%;"><h2>Téléphone</h2></td>
                    <td style="width: 49.7527%;">${copy.tel}</td>
                </tr>
                <tr>
                    <td style="width: 49.7516%;"><h2>Adresse Email</h2></td>
                    <td style="width: 49.7527%;">${copy.email}</td>
                </tr>
            </tbody>
        </table>

        <table style="border-collapse: collapse; width: 80%; border-color: #dba720; margin-left: auto; margin-right: auto;margin-top: 20px" border="2">
            <tr>
                <td>
                    <h2>Message</h2>
                    <p style="font-size: 16px;">${copy.message}</p>
                </td>
            </tr>
        </table>

        <table style="border-collapse: collapse; width: 80%; border-color: #dba720; margin-left: auto; margin-right: auto;margin-top: 20px" border="2">
            <tr>
                <td>
                    <h2>Quel est le but de la communication que notre agence créera pour le produit/service ?</h2>
                    <p style="font-size: 16px;">${copy.communication}</p>
                </td>
            </tr>
        </table>

        <table style="border-collapse: collapse; width: 80%; border-color: #dba720; margin-left: auto; margin-right: auto;margin-top: 20px" border="2">
            <tr>
                <td style="padding-bottom: 16px;">
                    <h2>Quelle est l'audience du produit/service ?</h2>
                    <div style="display:flex">
                        <p style="margin:0;width:10%;${copy.audience['jeune-mature'] === 0?'color:blue':''}">Jeune</p>
                        <div style="width:80%; height:8px;border-radius:50px; background:lightgray; margin-left:10px; margin-right:10px; margin-top: 6px;">
                            <div style="width:${copy.audience['jeune-mature']}%;height:100%; background:#243C39; border-radius:50px;  "></div>
                        </div>
                        <p style="margin:0;width:10%">Mature</p>
                    </div>

                    <div style="display:flex">
                        <p style="margin:0;width:10%;${copy.audience['feminine-masculine'] === 0?'color:blue':''}">Féminine</p>
                        <div style="width:80%; height:8px;border-radius:50px; background:lightgray; margin-left:10px; margin-right:10px; margin-top: 6px;">
                            <div style="width:${copy.audience['feminine-masculine']}%;height:100%; background:#243C39; border-radius:50px;  "></div>
                        </div>
                        <p style="margin:0;width:10%">Masculine</p>
                    </div>

                    <div style="display:flex">
                        <p style="margin:0;width:10%;${copy.audience['national-international'] === 0?'color:blue':''}">National</p>
                        <div style="width:80%; height:8px;border-radius:50px; background:lightgray; margin-left:10px; margin-right:10px; margin-top: 6px;">
                            <div style="width:${copy.audience['national-international']}%;height:100%; background:#243C39; border-radius:50px;  "></div>
                        </div>
                        <p style="margin:0;width:10%">International</p>
                    </div>
                </td>
            </tr>
        </table>

        <table style="border-collapse: collapse; width: 80%; border-color: #dba720; margin-left: auto; margin-right: auto;margin-top: 20px" border="2">
            <tr>
                <td>
                    <h2>Quelle est l'opinion actuelle du public à propos du service ?</h2>
                    <p style="font-size: 16px;">${copy['opinion-service']}</p>
                </td>
            </tr>
        </table>

        <table style="border-collapse: collapse; width: 80%; border-color: #dba720; margin-left: auto; margin-right: auto;margin-top: 20px" border="2">
            <tr>
                <td>
                    <h2>Que voulons-nous que le public fasse ?</h2>
                    <p style="font-size: 16px;">${copy['public-fasse']}</p>
                </td>
            </tr>
        </table>

        <table style="border-collapse: collapse; width: 80%; border-color: #dba720; margin-left: auto; margin-right: auto;margin-top: 20px" border="2">
            <tr>
                <td>
                    <h2>Quelle est l'idée la plus simple que nous devons transmettre ?</h2>
                    <p style="font-size: 16px;">${copy['quoi-transmettre']}</p>
                </td>
            </tr>
        </table>

        <table style="border-collapse: collapse; width: 80%; border-color: #dba720; margin-left: auto; margin-right: auto;margin-top: 20px" border="2">
            <tr>
                <td style="padding-bottom: 16px;">
                    <h2>Quelle est la personnalité que nous voulons véhiculer ?</h2>

                    <div style="display:flex">
                        <p style="margin:0;width:10%;${copy.personnaliter['luxueux-economique'] === 0?'color:blue':''}">Luxueux</p>
                        <div style="width:80%; height:8px;border-radius:50px; background:lightgray; margin-left:10px; margin-right:10px; margin-top: 6px;">
                            <div style="width:${copy.personnaliter['luxueux-economique']}%;height:100%; background:#243C39; border-radius:50px;  "></div>
                        </div>
                        <p style="margin:0;width:10%">Economique</p>
                    </div>

                    <div style="display:flex">
                        <p style="margin:0;width:10%;${copy.personnaliter['classique-moderne'] === 0?'color:blue':''}">Classique</p>
                        <div style="width:80%; height:8px;border-radius:50px; background:lightgray; margin-left:10px; margin-right:10px; margin-top: 6px;">
                            <div style="width:${copy.personnaliter['classique-moderne']}%;height:100%; background:#243C39; border-radius:50px;  "></div>
                        </div>
                        <p style="margin:0;width:10%">Moderne</p>
                    </div>

                    <div style="display:flex">
                        <p style="margin:0;width:10%;${copy.personnaliter['ludique-serieux'] === 0?'color:blue':''}">Ludique</p>
                        <div style="width:80%; height:8px;border-radius:50px; background:lightgray; margin-left:10px; margin-right:10px; margin-top: 6px;">
                            <div style="width:${copy.personnaliter['ludique-serieux']}%;height:100%; background:#243C39; border-radius:50px;  "></div>
                        </div>
                        <p style="margin:0;width:10%">Sérieux</p>
                    </div>

                    <div style="display:flex">
                        <p style="margin:0;width:10%;${copy.personnaliter['discret-voyant'] === 0?'color:blue':''}">Discret</p>
                        <div style="width:80%; height:8px;border-radius:50px; background:lightgray; margin-left:10px; margin-right:10px; margin-top: 6px;">
                            <div style="width:${copy.personnaliter['discret-voyant']}%;height:100%; background:#243C39; border-radius:50px;  "></div>
                        </div>
                        <p style="margin:0;width:10%">Voyant</p>
                    </div>

                    <div style="display:flex">
                        <p style="margin:0;width:10%;${copy.personnaliter['simple-complexe'] === 0?'color:blue':''}">Simple</p>
                        <div style="width:80%; height:8px;border-radius:50px; background:lightgray; margin-left:10px; margin-right:10px; margin-top: 6px;">
                            <div style="width:${copy.personnaliter['simple-complexe']}%;height:100%; background:#243C39; border-radius:50px;  "></div>
                        </div>
                        <p style="margin:0;width:10%">Complexe</p>
                    </div>
                </td>
            </tr>
        </table>

        <table style="border-collapse: collapse; width: 80%; border-color: #dba720; margin-left: auto; margin-right: auto;margin-top: 20px" border="2">
            <tr>
                <td>
                    <h2>Quels sont les livrables ?</h2>
                    ${copy['livrable']}
                </td>
            </tr>
        </table>

        <table style="border-collapse: collapse; width: 80%; border-color: #dba720; margin-left: auto; margin-right: auto;margin-top: 20px" border="2">
            <tr>
                <td>
                    <h2>Y a-t-il des matériaux sources ?</h2>
                    <p style="font-size: 16px;">${copy['materiaux']}</p>
                </td>
            </tr>
        </table>

        <table style="border-collapse: collapse; width: 80%; border-color: #dba720; margin-left: auto; margin-right: auto;margin-top: 20px" border="2">
            <tr>
                <td>
                    <h2>Ce contenu fait-il partie d'une campagne plus large ?</h2>
                    <p style="font-size: 16px;">${copy['campagne']}</p>
                </td>
            </tr>
        </table>

        <table style="border-collapse: collapse; width: 80%; border-color: #dba720; margin-left: auto; margin-right: auto;margin-top: 20px" border="2">
            <tr>
                <td>
                    <h2>Quelles informations supplémentaires seraient utiles à l'équipe de création ?</h2>
                    <p style="font-size: 16px;">${copy['equipe-creation-utils']}</p>
                </td>
            </tr>
        </table>
        
        `


        emailjs.send("service_lklq3s7", "template_9lgivrj", copy, "user_bREg8PQQNXaLw6gdAVuP2")
            .then(function(response) {
                enqueueSnackbar("Opération réussie avec succès!",{
                    variant:"success"
                })
             }, function(error) {
                enqueueSnackbar("Opération échouée. Veuillez réessayer.",{
                    variant:"error"
                })
             });
    }


    return (
        <div className="box-quiz">
            <div className="pourcentage">
                <div className={classes.step} style={{width:`${((100/(quiz.length-1))*question)}%`}}></div>
            </div>
            <form onSubmit={handleSubmit}>
            <div className="content">
                <Typography variant="h5" style={{color:"#243C39"}} className="question">{translate.language === 'fr'?quiz[question].question_fr:quiz[question].question_en}</Typography>
                {
                    quiz[question].type === "input"?(
                        quiz[question].contenu.map((e, index) => (
                            e.type === 'input'?(
                                <TextField className={[classes.root,classes.marge]}
                                name={e.name}
                                label={translate.language === 'fr'?e.label_fr:e.label_en} 
                                variant="outlined" 
                                onChange={(event) => setForm({... form, [e.name]: event.target.value})} 
                                value={form[e.name]} 
                                required={e.require}
                                type={e.typet}
                                />
                            ):(
                                <TextField 
                                className={classes.root}
                                label={translate.language === 'fr'?e.label_fr:e.label_en} 
                                name={e.name}
                                multiline 
                                rows={4} 
                                variant="outlined" 
                                onChange={(event) => setForm({... form, [e.name]: event.target.value})}
                                value={form[e.name]} 
                                />
                            )
                        ))
                    ):quiz[question].type === "radio"?(
                        <FormControl component="fieldset">
                        <RadioGroup 
                        name={quiz[question].name} 
                        value={form[quiz[question].name]} 
                        onChange={(event) => setForm({... form, [quiz[question].name]: event.target.value})}>
                            {
                                translate.language === 'fr'?(
                                quiz[question].contenu_fr.map(e =>(
                                  <FormControlLabel value={e} control={<CustumRadio />} label={e} />  
                                ))
                                ):(
                                    quiz[question].contenu_en.map((e, index) =>(
                                        <FormControlLabel value={quiz[question].contenu_fr[index]} control={<CustumRadio />} label={e} />  
                                      ))  
                                )
                            }
                        </RadioGroup>
                        </FormControl>
                    ):quiz[question].type === "slider"?(
                        translate.language === 'fr'?(
                            quiz[question].contenu_fr.map((e, index) =>(
                                <Grid container spacing={2} style={{marginTop:"25px"}}>
                                    <Grid item className="label-start">
                                        <p>{e.marks[0].label}</p>
                                    </Grid>
                                    <Grid item xs>
                                    <CustumSlider 
                                    value={form[quiz[question].name][e.name]}
                                    name={e.name}
                                    aria-labelledby="discrete-slider-custom"
                                    step={10}
                                    onChange={(event, newValue) => setForm({... form, [quiz[question].name]:{... form[quiz[question].name], [e.name]: newValue}})}
                                    valueLabelDisplay="auto"
                                    /> 
                                    </Grid>
                                    <Grid item className="label-end">
                                        <div>
                                            <p>{e.marks[1].label}</p>
                                        </div>
                                    </Grid>
                                </Grid> 
                            ))
                        ):(
                            quiz[question].contenu_en.map((e, index) =>(
                                <Grid container spacing={2}>
                                    <Grid item className="label-start">
                                        <p>{e.marks[0].label}</p>
                                    </Grid>
                                    <Grid item xs>
                                    <CustumSlider 
                                    value={form[quiz[question].name][e.name]}
                                    name={e.name}
                                    aria-labelledby="discrete-slider-custom"
                                    step={10}
                                    onChange={(event, newValue) => setForm({... form, [quiz[question].name]:{... form[quiz[question].name], [e.name]: newValue}})}
                                    valueLabelDisplay="auto"
                                    style={{marginTop:"25px"}}
                                    /> 
                                    </Grid>
                                    <Grid item className="label-end">
                                        <div>
                                          <p>{e.marks[1].label}</p>  
                                        </div>
                                    </Grid>
                                </Grid>  
                              ))
                        )
                        
                    ):quiz[question].type === "checkbox"?(
                        
                        quiz[question].contenu.map((e, index) => (
                        <React.Fragment>
                            <FormControlLabel
                            control={
                            <CustumCheckbox 
                                checked={form.livrables[e.name]}
                                onChange={(event) => setForm({... form, livrables: {... form.livrables, [e.name]: event.target.checked}})}
                                name={e.name}
                            />
                            }
                            label={translate.language === 'fr'?e.label_fr:e.label_en}
                            />
                            {
                                e.name === "autre"?(
                                    <TextField className={[classes.root,classes.marge]}
                                        name={'autreText'}
                                        variant="standard"
                                        style={{display:`${form.livrables[e.name] === true?'inline-flex':'none'}`}}
                                        disabled={!form.livrables[e.name]}
                                        onChange={(event) => setForm({... form, ['autreText']: event.target.value})} 
                                        value={form['autreText']} 
                                        />
                                    ):null
                            }
                        </React.Fragment>
                        ))
                    
                        
                    ):null
                }
                <div style={{marginTop:"15px"}}>
                    {
                        question === 0?(
                            quiz[question].type === "input"?(
                                quiz[question].contenu.filter(e => form[e.name] === "").length > 0?(
                                    <Button disabled variant="contained" className="button-suivant" onClick={(event) => setQuestion(question+1)}>Suivant</Button>
                                ):(
                                    <Button variant="contained" className="button-suivant" onClick={(event) => setQuestion(question+1)}>Suivant</Button>   
                                )
                            ):(
                                <Button variant="contained" className="button-suivant" onClick={(event) => setQuestion(question+1)}>Suivant</Button>
                            )
                        ):question === (quiz.length-1)?(
                            <React.Fragment>
                            <Button variant="contained" className="button-precedent" onClick={(event) => setQuestion(question-1)}>Retour</Button>
                            <Button variant="contained" className="button-suivant" type="submit">{t("devis.bouton_envoyer")}</Button>
                            </React.Fragment>
                        ):(
                            <React.Fragment>
                            <Button variant="contained" className="button-precedent" onClick={(event) => setQuestion(question-1)}>Retour</Button>
                            {
                                quiz[question].type === "input"?(
                                    quiz[question].contenu.filter(e => form[e.name] === "").length > 0?(
                                        <Button disabled variant="contained" className="button-suivant" onClick={(event) => setQuestion(question+1)}>Suivant</Button>
                                    ):(
                                        <Button variant="contained" className="button-suivant" onClick={(event) => setQuestion(question+1)}>Suivant</Button>   
                                    )
                                ):(
                                    <Button variant="contained" className="button-suivant" onClick={(event) => setQuestion(question+1)}>Suivant</Button>
                                )
                            }
                            </React.Fragment>
                        )
                    }
                </div>
                
            </div>
            </form>
        </div>
    )
}
