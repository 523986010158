import { Box, Button } from '@material-ui/core'
import React from 'react'
import {useTranslation} from "react-i18next";
import emailjs from 'emailjs-com';
import { useSnackbar } from 'notistack'

export const FormContact = () => {
    const [t, translate] = useTranslation('common');
    const { enqueueSnackbar } = useSnackbar()
    const [inputRequired, setInputRequired] = React.useState({
        nom:false,
        email:false,
        tel:false
    })

    const [form, setForm] = React.useState({
        nom:"",
        tel:"",
        email:"",
        message:"",
        formType:"de prise de contact",
        'html-content':""
    })

    const handleSubmit = (event) => {
        event.preventDefault();
        let copy = {... form}

        copy['html-content'] = `
        <table style="border-collapse: collapse; width: 80%; border-color: #dba720; margin-left: auto; margin-right: auto;" border="2">
            <tbody>
                <tr>
                    <td style="width: 49.7516%;"><h2>Nom</h2></td>
                    <td style="width: 49.7527%;">${copy.nom}</td>
                </tr>
                <tr>
                    <td style="width: 49.7516%;"><h2>Téléphone</h2></td>
                    <td style="width: 49.7527%;">${copy.tel}</td>
                </tr>
                <tr>
                    <td style="width: 49.7516%;"><h2>Adresse Email</h2></td>
                    <td style="width: 49.7527%;">${copy.email}</td>
                </tr>
            </tbody>
        </table>
        <table style="border-collapse: collapse; width: 80%; border-color: #dba720; margin-left: auto; margin-right: auto;margin-top: 20px" border="2">
            <tr>
                <td style="width: 49.7527%;">
                    <h2>Message</h2>
                    <p>${copy.message}</p>
                </td>
            </tr>
        </table>
        `
        if(form.nom !== "" && form.email !== "" && form.tel !== "" ){
            emailjs.send("service_lklq3s7", "template_9lgivrj", copy, "user_bREg8PQQNXaLw6gdAVuP2")
            .then(function(response) {
                enqueueSnackbar("Opération réussie avec succès!",{
                    variant:"success"
                })
             }, function(error) {
                enqueueSnackbar("Opération échouée. Veuillez réessayer.",{
                    variant:"error"
                })
             });
        } else {
            let copy = {... inputRequired}
            if(form.nom === ""){
                copy.nom = true
                setInputRequired({... copy, nom:true})
            }
            if(form.email === ""){
                copy.email = true
                setInputRequired({... copy, email:true})
            }
            if(form.tel === ""){
                copy.tel = true
                setInputRequired({... copy, tel:true})
            }
        }
    }

    return (
        <form className='contact-form' onSubmit={handleSubmit}>
            <Box display="flex" flexDirection="column">
                <label>{t("devis.label_nom")}</label>
                <input type='txt' value={form.nom} required={inputRequired.nom} onChange={(e) => {
                                    setForm({... form, nom: e.target.value});
                                    setInputRequired({... inputRequired, nom: false})
                                    }} /> 
                <label>{t("devis.label_telephone")}</label>
                <input type='tel' value={form.tel} required={inputRequired.tel} onChange={(e) => {
                                    setForm({... form, tel: e.target.value});
                                    setInputRequired({... inputRequired, tel: false})
                                    }} />
                <label>{t("devis.label_email")}</label>
                <input type='email' value={form.email} required={inputRequired.email} onChange={(e) => {
                                    setForm({... form, email: e.target.value});
                                    setInputRequired({... inputRequired, email: false})
                                    }} /> 
                <label>{t("devis.label_message")}</label>
                <textarea type="txt" value={form.message} onChange={(e) => setForm({... form, message: e.target.value})}></textarea>
                <Button style={{left:"100%", transform: "translateX(-100%)"}} variant="contained" className="button-suivant" type="submit">{t("devis.bouton_envoyer")}</Button>
            </Box>
        </form>
    )
}
