import {URL_API} from '../config'

export const findoneService = (name) => {
    return new Promise((resolve, reject) => {
        fetch(`${URL_API}/services?title_fr=${name}`, {
            method: "GET",
            headers:{
                'Content-Type': 'application/json'
            }
        })
        .then(response => response.json())
        .then(data => {
            if(data.length > 0){
                resolve(data)
            } else {
                reject("service introuvable")
            }
        })
        .catch((error) => {
            reject(error)
        })
    })
}

export const findServices = () => {
    return new Promise((resolve, reject) => {
        fetch(`${URL_API}/services`, {
            method: "GET",
            headers:{
                'Content-Type': 'application/json'
            }
        })
        .then(response => response.json())
        .then(data => {
            if(data.length > 0){
                resolve(data)
            } else {
                reject("service introuvable")
            }
        })
        .catch((error) => {
            reject(error)
        })
    })
}