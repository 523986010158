import { Grid } from '@material-ui/core'
import React from 'react'
import { TitleheaderLine } from '../texte'
import {CarouselTestimonial} from '../carousel/CarouselTestimonial'
import {useTranslation} from "react-i18next";

export const Testimonial = () => {
    const [t, translate] = useTranslation('common');
    return (
        <Grid container justify="center" className="testimonial-bloc bloc-jaune bloc-padding">
            <Grid item xs={11}>
                <Grid container justify="center">
                    <TitleheaderLine info={{title:t('accueil.testimonial_title'), color:"bleu"}} />
                    <Grid item xs={12}>
                        <CarouselTestimonial />
                    </Grid>
                </Grid>
            </Grid>   
        </Grid>
    )
}
