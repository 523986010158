import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  AppBar, Toolbar, List, ListItem,
  withStyles, Grid, SwipeableDrawer, Box
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import {Link} from 'react-router-dom'
import {Button} from '@material-ui/core';
import {Flags} from './Flags'
import {findLogo} from '../api/Logo'
import {URL_API} from '../config'
import { findServices } from '../api/services'
import { withTranslation } from 'react-i18next';
import compose from 'recompose/compose';
import { FlagsMobile } from './FlagsMobile';

import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
const styleSheet = {
  navBar : {
    position:'relative',
    background:"rgba(0, 0, 0, 0)",
    padding: "15px",
    paddingRight:"50px",
    paddingLeft:"50px",
    borderBottom:'1px solid #FFDA25',
    borderTop:"5px solid #ECA800",
    transition:"0.5s",
    zIndex:"1500"
  },
  navBarMobile : {
    position:'relative',
    background:"rgba(0, 0, 0, 0)",
    padding: "15px",
    paddingRight:"0px",
    paddingLeft:"0px",
    borderBottom:'1px solid #FFDA25',
    borderTop:"5px solid #ECA800",
    transition:"0.5s",
    zIndex:"1500"
  },
  navMobil:{
    height:"100%",
    width : '70vw',
  },
  list : {
    width : '100%',
    listStyle:'none',
    marginTop:"50px",
  },
  padding : {
    paddingRight : 30,
    cursor : "pointer",
    width: "max-content"
  },

  sideBarIcon : {
    padding : 0,
    color : "white",
    cursor : "pointer",
  },
}

const BootstrapButton = withStyles({
  root: {
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 16,
    padding: '6px 12px',
    borderRadius:'100px',
    lineHeight: 1.5,
    background: 'linear-gradient(90deg, #AD8619 -1.98%, #DBA720 24.43%, #FFDA25 59.82%, #FAE891 99.99%);',
    color:"#243C39",
    width:"20%",
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:hover': {
      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    },
  },
})(Button);

class Navigation extends Component{
  constructor(props){
    super(props);
    this.state = {drawerActivate:false, drawer:false, logo:"", sticky:false, services:[]};
    this.createDrawer = this.createDrawer.bind(this);
    this.destroyDrawer = this.destroyDrawer.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  componentWillMount(){
    if(window.innerWidth <= 900){
      this.setState({drawerActivate:true});
    }
    window.addEventListener('resize',()=>{
      if(window.innerWidth <= 900){
        this.setState({drawerActivate:true});
      }
      else{
        this.setState({drawerActivate:false})
      }
    });
    this.props.setServiceShow(false)
    // api
    findLogo()
    .then(response => {
      this.setState({
        logo: response.logo.url
      })
    })
    .catch(error => {
      alert(error)
    })

    findServices()
    .then(response => {
      this.setState({services:response})
    })

    const sticky = 0;
    const scrollCallBack = window.addEventListener("scroll", () => {
      if (window.pageYOffset > sticky) {
        this.setState({sticky:true})
      } else {
        this.setState({sticky:false})
      }
    });
    return () => {
      window.removeEventListener("scroll", scrollCallBack);
    };
  }

  //Small Screens
  createDrawer(translate){
    return (
      <div>
        <AppBar className={[this.props.classes.navBarMobile, this.state.sticky?"sticky":""]}>
          <Toolbar>
            <Grid container direction = "row" justify = "space-between" alignItems="center">
              <MenuIcon
                className = {this.props.classes.sideBarIcon}
                onClick={()=>{this.setState({drawer:true})}} />

              <Link to="/">
              <img alt="tasmeem logo" src={`${URL_API}${this.state.logo}`} height="auto" width="70%" style={{position:"relative",width:"70%"}} />
              </Link>
            </Grid>
          </Toolbar>
        </AppBar>

        <SwipeableDrawer
         open={this.state.drawer}
         onClose={()=>{this.setState({drawer:false})}}
         onOpen={()=>{this.setState({drawer:true})}}
         style={{zIndex:"3000"}}>
         
           <div
             tabIndex={0}
             className={this.props.classes.navMobil}
             role="button"
             onClick={()=>{this.setState({drawer:false})}}
             onKeyDown={()=>{this.setState({drawer:false})}}>
            <List className = {this.props.classes.list}>
                <Link to="/Apropos">
                <ListItem className="link-mobile" key = {1} button> À Propos </ListItem>
                </Link>
                {
                  this.state.services.map((e, index) => (
                    <Link to={`/service/${e.title_fr}`}>
                    <ListItem className="link-mobile" key = {index+2} button> {translate.language === "fr"?e.title_fr:e.title_en} </ListItem>
                    </Link>
                  ))
                }
                
                {/* <Link to="/project">
                <ListItem className="link-mobile" key = {6} button> {translate.language === "fr"?"Nos projets":"Our projects"} </ListItem>
                </Link> */}
                {/* <Link to="/recrutement">
                <ListItem className="link-mobile" key = {7} button> {translate.language === "fr"?"Emplois":"Jobs"} </ListItem>
                </Link> */}

                <Link to="/blog">
                <ListItem className="link-mobile" key = {8} button> {translate.language === "fr"?"Blog":"Blog"} </ListItem>
                </Link>
                
                <Link to="/contact">
                <ListItem className="link-mobile" key = {9} button> {translate.language === "fr"?"Contact":"Contact us"}  </ListItem>
                </Link>
             </List>
             <FlagsMobile/>
         </div>
       </SwipeableDrawer>

      </div>
    );
  }

  handleClick(){
    if(this.props.serviceShow){
      window.document.getElementById("dropdown-content").className = "dropdown-content"; 
      window.document.getElementById("dropdown-content").style= "height:0"
      this.props.setServiceShow(false)
    } else {
      window.document.getElementById("dropdown-content").className = "dropdown-content dropdown-content-show"
      window.document.getElementById("dropdown-content").style= "height:auto;"
      ; 
      this.props.setServiceShow(true)
    }
  }

  //Larger Screens
  destroyDrawer(translate){
    const {classes} = this.props
    return (
      <AppBar id='myHeader' className={[classes.navBar,this.state.sticky?"sticky":""]}>
        <Toolbar>
          <Box display="flex" justifyContent="space-between" style={{width:"100%"}}>
            <Box>
              <Link to="/">
              <img alt="tasmeem logo" src={`${URL_API}${this.state.logo}`} height="80%" width="auto" style={{position:"relative", top:"50%", transform:'translateY(-50%)'}} />
              </Link>
            </Box>
            <Box display="flex" justifyContent="start" style={{height:"100%", alignSelf: "center", marginRight:"50px"}}>
              <Link to="/Apropos" className={classes.padding}>
              {translate.language === "fr"?"À propos":"About us"}
              </Link>
              <div className="DropDown" onClick={this.handleClick}>
              <Link>{translate.language === "fr"?"Services":"Services"} {this.props.serviceShow?(<ArrowUpwardIcon style={{color:"#FFDA25", fontSize:"18px"}}/>):(<ArrowDownwardIcon style={{color:"#FFDA25", fontSize:"18px"}}/>)}</Link>
              <div id="dropdown-content" className="dropdown-content">
              {
                this.state.services.map(e => (
                  <React.Fragment>
                  <Link to={`/service/${e.title_fr}`} className={classes.padding}>
                    {translate.language === "fr"?e.title_fr:e.title_en}
                  </Link>
                  </React.Fragment>
                ))
              }
              </div>
              </div>
              {/* <Link to="/project" className={classes.padding}>
              {translate.language === "fr"?"Nos projets":"Our projects"}
              </Link>
              <Link to="/recrutement" className={classes.padding}>
                {translate.language === "fr"?"Emplois":"Jobs"}
              </Link> */}
              <Link to="/blog" className={classes.padding}>
                {translate.language === "fr"?"Blog":"Blog"}
              </Link>
              <Link to="/contact" className={classes.padding}>
              {translate.language === "fr"?"Contact":"Contact us"}
              </Link>
            </Box>
          </Box>
        </Toolbar>
        <Flags/>
      </AppBar>
    )
  }

  render(){
    const {t, i18n} = this.props;
    return(
      <div>
        {this.state.drawerActivate ? this.createDrawer(i18n) : this.destroyDrawer(i18n)}
      </div>
    );
  }
}

Navigation.propTypes = {
  classes : PropTypes.object.isRequired
};


export default compose(withStyles(styleSheet),withTranslation())(Navigation);