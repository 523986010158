import { Grid } from '@material-ui/core'
import React from 'react'
import { findPartenaire } from '../../api/Partenaires'
import { URL_API } from '../../config'
import { TitleheaderLine } from '../texte'
import {useTranslation} from "react-i18next";

export const Partenaires = () => {
    const [partenaires, setPartenaire] = React.useState(null)
    const [t, translate] = useTranslation('common');

    React.useEffect(() => {
        findPartenaire()
        .then(response => {
            setPartenaire(response)
        })
    }, [])

    return partenaires? (
        <Grid container justify="center" className="bloc-padding">
            <Grid item xs={10}>
                <Grid container justify="center">
                    <TitleheaderLine info={{title:translate.language === 'fr'?"Nos clients":"Our clients", color:"jaune"}} textColor="#fff" />
                </Grid>
                <Grid container spacing={5} justify="center">
                    {
                        partenaires.logo.map(e => (
                        <Grid item xs={6} lg={2}>
                            <img alt="tasmeem partenaire" src={`${URL_API}${e.url}`} style={{width:"100%"}} />
                        </Grid>
                        ))
                    }
                </Grid>
            </Grid>
        </Grid>
    ):null
}
