import {URL_API} from '../config'
export const GetBlogs = (filter) => {
    return new Promise((resolve, reject) => {
        fetch(`${URL_API}/blogs?${filter} `, {
            method: "GET",
            headers:{
                'Content-Type': 'application/json'
            }
        })
        .then(response => response.json())
        .then(data => {  
            console.log("data : ", data)
            resolve(data)
        })
        .catch((error) => {
            reject(error)
        })
    })
}

export const GetLastArticles = () => {
    return new Promise((resolve, reject) => {
        fetch(`${URL_API}/blogs?_sort=published_at:DESC&_limit=1`, {
            method: "GET",
            headers:{
                'Content-Type': 'application/json'
            }
        })
        .then(response => response.json())
        .then(data => {  
            resolve(data)
        })
        .catch((error) => {
            reject(error)
        })
    })
}

export const GetCategories = () => {
    return new Promise((resolve, reject) => {
        fetch(`${URL_API}/categorie-articles`, {
            method: "GET",
            headers:{
                'Content-Type': 'application/json'
            }
        })
        .then(response => response.json())
        .then(data => {  
            resolve(data)
        })
        .catch((error) => {
            reject(error)
        })
    })
}

export const GetBlog = (id) => {
    return new Promise((resolve, reject) => {
        fetch(`${URL_API}/blogs/${id} `, {
            method: "GET",
            headers:{
                'Content-Type': 'application/json'
            }
        })
        .then(response => response.json())
        .then(data => {  
            console.log("data : ", data)
            if(data && !data.error){
                resolve(data)
            } else {
                reject("article introuvable")
            }
        })
        .catch((error) => {
            console.log("error : ", error)
            reject(error)
        })
    })
}

export const GetCategoCountsBlog = (id) => {
    return new Promise((resolve, reject) => {
        fetch(`${URL_API}/blogs/${id} `, {
            method: "GET",
            headers:{
                'Content-Type': 'application/json'
            }
        })
        .then(response => response.json())
        .then(data => {  
            console.log("data : ", data)
            resolve(data)
        })
        .catch((error) => {
            reject(error)
        })
    })
}