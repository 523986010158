import { Grid } from '@material-ui/core'
import React from 'react'
import { Title } from '../components/texte'
import {useTranslation} from "react-i18next";
import { Bouton } from '../components/bouton';
import {findpage} from '../api/page'
import {URL_API} from '../config'
import { Loader } from '../components/Loader';
import { MetaTags } from '../components/MetaTags'

export const PageIntrouvable = () => {
    const [t, translate] = useTranslation('common');
    const [infoPage, setInfoPage] = React.useState(null)
    const [images, setimages] = React.useState(null)
    const [loading, setLoading] = React.useState(true)

    React.useEffect(() => {
        findpage('404')
        .then(response =>{
            setInfoPage(response[0])
            setimages(response[0].images)
            const tt = response[0].images
            setLoading(false)
        })
        .catch(error =>{
        })
    }, [])
    return !loading ?(
        <>
        <MetaTags
            meta={
                {
                    title:translate.language === 'fr'?"Tasmeem - Agence de design print & digital internationale":"Tasmeem - International print & digital design agency", 
                    description:translate.language === 'fr'?"est une agence de communication internationale, axée sur le design print et digital qui est basée au Maroc. Nous créons pour vous un Branding clé et une stratégie de déploiement pour permettre à votre prodTasmeem uit ou service d'être identifiable, promu et démarqué, que cela soit via une identité visuelle, une présence digitale et audiovisuelle, au packaging produit ou encore à un travail éditorial. Nous comblons tous vos besoins en la matière avec une vision 360° de nos projets.":"Tasmeem is an international communication agency, focused on print and digital design, based in Morocco. We create for you a key branding and deployment strategy to allow your product or service to be identifiable, promoted and differentiated, whether it be through a visual identity, a digital and audiovisual presence, product packaging or editorial work. We meet all your needs in this area thanks to a 360° vision of our projects.",
                    url:window.location.href,
                    image:"https://tasmeem.ma/assets/logo/logo.png",
                }
            } />
        {
            infoPage && images?(
                <Grid container className="bloc-padding">
                    <Grid item xs={12} style={{textAlign:"center", marginBottom:"50px"}}>
                        <Title txt={t('page_404.title')} style={{color:"#fff"}} />
                        <Bouton txt={t('page_404.Bouton')} path='/'/>                        
                    </Grid>
                    <Grid item xs={12} style={{textAlign:"center"}}>
                        <img style={{maxWidth:"50%"}} src={`${URL_API}${images.filter(i => i.name === "404")[0].image.url}`} />   
                    </Grid>
                </Grid>
            ): null
        }
        </>
    ):(<Loader />)
}
