import './App.scss';
import { useTranslation } from "react-i18next";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
// nav
import Navigation from './components/Navigation'
// footer
import { Footer } from './components/footer/Footer'
// pages
import {
  Accueil,
  Apropos,
  Contact,
  Mentions,
  PageIntrouvable,
  Recrutement,
  Service,
  Quiz,
  Maintenance,
  Blog,
  BlogArticle
} from './pages'
import { Contactez } from './components/accueil/index'
import { Devis } from './components/devis/Devis'
import ScrollToTop from './components/ScrollToTop';
import Hidden from '@material-ui/core/Hidden';
import { Project } from './pages/Project';
import React from 'react';
import { useHistory } from "react-router-dom";
import FloatingWhatsApp from 'react-floating-whatsapp'
import { findInformations } from './api/information';

// last update 2.0
function HeaderComponent() {
  const [t, translate] = useTranslation('common');
  return <div>
    <h1>{t('acceuil.description')}</h1>
    <button onClick={() => translate.changeLanguage('de')}>de</button>
    <button onClick={() => translate.changeLanguage('en')}>en</button>
    <button onClick={() => translate.changeLanguage('fr')}>fr</button>
  </div>
}

function App() {
  const [t, translate] = useTranslation('common');
  const test = t('acceuil', { returnObjects: true })
  const maintenance = false;
  const [information, setInformation] = React.useState(null)
  const [serviceShow, setServiceShow] = React.useState(false)
  Object.keys(test).map(function (key, index) {
    
  });
  React.useEffect(() => {
    if (window.location.hostname == "tasmeem-b88c9.web.app" || 
        window.location.hostname == "tasmeem-b88c9.firebaseapp.com") {
          window.location.href = 'https://tasmeem.ma' 
    }
    findInformations()
        .then(response =>{
            setInformation(response)
        })
        .catch(error =>{
            alert(error)
        })
  }, [])

  return !maintenance ? (
    <>

      <Router>
        <ScrollToTop />
        <Navigation serviceShow={serviceShow} setServiceShow={setServiceShow} />
        {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
        <Switch>
          <Route exact path="/service/:nom">
            <Service />
          </Route>
          <Route exact path="/mentions-legales">
            <Mentions />
          </Route>
          <Route exact path="/Apropos">
            <Apropos />
          </Route>
          <Route exact path="/quiz">
            <Quiz />
          </Route>
          <Route strict path="/contact">
            <Contact />
          </Route>
          <Route strict path="/contact#rdv">
            <Contact rdv />
          </Route>
          {/* <Route strict path="/project">
            <Project />
          </Route> */}
          <Route exact path="/maintenance">
            <Maintenance />
          </Route>
          {/* <Route exact path="/recrutement">
            <Recrutement />
          </Route> */}
          <Route exact path="/blog">
            <Blog />
          </Route>
          <Route exact path="/blog/:title/:id">
            <BlogArticle />
          </Route>
          <Route exact path="/">
            <Accueil />
          </Route>
          <Route path='/404'>
            <PageIntrouvable />
          </Route>
          <Route path='*' exact={true}>
            <Redirect to="/404" />
          </Route>
        </Switch>
        {!window.location.pathname.includes('/mentions-legales') && !window.location.pathname.includes('/404') && !window.location.pathname.includes('/quiz') && !window.location.pathname.includes('/maintenance') && !window.location.pathname.includes('/contact') && !window.location.pathname.includes('/blog') ? <Contactez /> : null}
        <Footer />
        <Devis />
        <Hidden only={['xs', 'sm']}>
          <button className="button-devis-float" onClick={(event) => document.getElementById('devis').style = 'width:101%'}><span>{t('devis.bouton_devis')}</span></button>
        </Hidden>
      </Router>
      <FloatingWhatsApp
        phoneNumber={information?.coordonnees?.tel}
        accountName={information?.informations?.raison_social}
        avatar={process.env.PUBLIC_URL+"/android-chrome-192x192.png"}
        statusMessage={translate.language === 'fr'?"Répond généralement dans l'heure":"Typically replies within 1 hour"}
        allowClickAway
        placeholder={translate.language === 'fr'?"Tapez un message..":"Type a message.."}
        chatMessage={translate.language === 'fr'?"Bonjour! 🤝 \nComment pouvons-nous aider?":"Hello there! 🤝 \nHow can we help?"}
        notification
        notificationDelay={60000}
        notificationSound={false}
      />
    </>
  ) : (
    <div style={{ height: "max-content", position: "relative" }}>

      <Router>
        <ScrollToTop />
        <Navigation />
        {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
        <div style={{position:"relative", minHeight:"100vh"}}>
          <Switch>
            <Route exact path="/service/:nom">
              <Redirect to="/maintenance" />
            </Route>
            <Route exact path="/mentions-legales">
              <Redirect to="/maintenance" />
            </Route>
            <Route exact path="/Apropos">
              <Redirect to="/maintenance" />
            </Route>
            <Route exact path="/quiz">
              <Redirect to="/maintenance" />
            </Route>
            <Route strict path="/contact">
              <Redirect to="/maintenance" />
            </Route>
            <Route strict path="/project">
              <Redirect to="/maintenance" />
            </Route>
            <Route exact path="/maintenance">
              <Maintenance />
            </Route>
            <Route exact path="/">
              <Redirect to="/maintenance" />
            </Route>
            <Route path='/404'>
              <Redirect to="/maintenance" />
            </Route>
            <Route path='*' exact={true}>
              <Redirect to="/maintenance" />
            </Route>
          </Switch>
        </div>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
