import React from 'react'
import { Button, Grid, Typography, Box } from '@material-ui/core'
import { MetaTags } from '../components/MetaTags'
import { useTranslation } from "react-i18next";
import { TitleheaderLine } from '../components/texte';
import { Bouton } from '../components/bouton';
import { findInformations } from '../api/information'
import {URL_API} from '../config'
import { Article } from '../components/blog/Article';

import Disqus  from 'disqus-react';
import { DisqusComments } from '../components/DisqusComments';
import { GetBlog } from '../api/blogs';
import { useParams, useHistory } from 'react-router-dom'

import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';

// share

import {
    FacebookShareButton,
    LinkedinShareButton,
  } from "react-share";
  import ShareLink from 'react-linkedin-share-link'

  import fb from "../assets/icon/fb.svg";

  import lkd from "../assets/icon/lkd.svg";
import { Helmet } from 'react-helmet-async';


const data = {
    imageHeader:`/assets/provisoire/headerimagearticle.png`,
    title_fr:"Comment l’intelligence artificielle change le monde",
    title_en:"Comment l’intelligence artificielle change le monde en",
    description_fr:"Votre image de marque dépend de la manière dont les gens perçoivent votre entreprise, que cela soit stratégique ou accidentel. Mais créer une identité visuelle est avant tout une série d'actions que vous  entreprenez pour cultiver cette image de marque intentionnellement. Si vous avez une entreprise, vous avez des concurrents. Votre identité  visuelle vous permet d’établir votre position sur le marché en vous différenciant des autres, et ainsi de démontrer aux clients  visuellement, sans effort commercial, pourquoi ils devraient choisir votre entreprise plutôt qu’une autre. C’est un outil de persuasion. Une identité visuelle réussie vous aidera à accroître votre notoriété sur le marché. ",
    description_en:"Votre image de marque dépend de la manière dont les gens perçoivent votre entreprise, que cela soit stratégique ou accidentel. Mais créer une identité visuelle est avant tout une série d'actions que vous  entreprenez pour cultiver cette image de marque intentionnellement. Si vous avez une entreprise, vous avez des concurrents. Votre identité  visuelle vous permet d’établir votre position sur le marché en vous différenciant des autres, et ainsi de démontrer aux clients  visuellement, sans effort commercial, pourquoi ils devraient choisir votre entreprise plutôt qu’une autre. C’est un outil de persuasion. Une identité visuelle réussie vous aidera à accroître votre notoriété sur le marché. en ",
    author:{
        avatar:`${process.env.PUBLIC_URL+"/assets/provisoire/Union.png"}`,
        name:"Yasmine Chaouai",
        poste_fr:"Multidisciplinary designer",
        poste_en:"Multidisciplinary designer en",
        description_fr:"Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.  eprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.  eprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.  fugiat nulla pariatur.  eprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
        description_en:"Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.  eprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.  eprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.  fugiat nulla pariatur.  eprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. en",
        social:{
            facebook:"https://facebook.com",
            instagram:"https://facebook.com",
            linkedin:"https://facebook.com",
            twiter:"https://facebook.com",
            youtube:"https://facebook.com",
        }
    }

}

export const BlogArticle = () => {
    const [t, translate] = useTranslation('common');
    const [article, setArticle] = React.useState(null)
    const [date, setDate] = React.useState(null)
    let { id } = useParams();
    const history = useHistory()  

    React.useEffect(() => {
        GetBlog(id)
        .then(response => {
            setArticle(response)
            var dd = new Date(response.published_at)
            setDate(dd)
        })
        .catch(error => {
            history.push(`/404`)
        })
      
    }, [])

    const estimationTime = (des) => {
        let wordCount = des.match(/(\w+)/g).length;
        let time_to_read = Math.round(wordCount * (1/120));

        return time_to_read
    }

    const getHashtag = (catego) => {

        let copy = []
        catego.map(e => {
            copy.push('#'+e.nom)
        })

        return copy
    }


  return article && date? (
    <React.Fragment>
            <MetaTags
            meta={
                {
                    title:translate.language === 'fr'?`${article.title_fr} - Tasmeem`:`${article.title_en} - Tasmeem`, 
                    description:translate.language === 'fr'?`${article.title_fr}. ${article.description_fr.substring(0,100)}`:`${article.title_en}. ${article.description_en.substring(0,100)}`,
                    url:window.location.href,
                    image:URL_API + article.imageHeader.url,
                }
            } />
                <Grid container justify="center" className="">
                <Grid item xs={11} md={9}>
                <Breadcrumbs className='ariane' separator="›" aria-label="breadcrumb">
                <Link color="inherit" href="/blog">
                   Blog
                </Link>
                <Typography color="textPrimary">{translate.language === 'fr'?article.title_fr:article.title_en}</Typography>
                </Breadcrumbs>
                </Grid>
                </Grid>

            <Grid container justify="center" className="expertise-bloc bloc-padding" style={{paddingTop:"50px"}}>
                <Grid item xs={11} md={9}>
                <TitleheaderLine info={{ title: translate.language === 'fr'?article.title_fr:article.title_en, color: "jaune" }} width={20} variant="h3" component="h1" textColor="#fff" />
                    <div className='d-flex justify-content-between'>
                        <p className='article-time'>{translate.language === 'fr'?"Temps de lecture :":"Reading time :"} {translate.language === 'fr'?estimationTime(article.description_fr):estimationTime(article.description_en)} min</p>
                        
                        <Box display='flex' className="around">
                            <FacebookShareButton
                                url={`https://tasmeem.ma/blog/${article.title_fr.replace(/\s+/g, '%20')}/${article._id}`}
                                hashtag={getHashtag(article.categories)}
                                quote={translate.language === "fr"? article.title_fr + ", " + article.description_fr.substring(0,250): article.title_en + ", " +  article.description_en.substring(0,250)}
                                className="Demo__some-network__share-button"
                            >
                                <img src={fb} className='iconArticle' width="0.5em" height="0.5em" alt='facebook share' style={{width:"0.5em", height:"0.5em", marginRight:"15px"}} />
                            </FacebookShareButton>
                            <LinkedinShareButton
                                url={`https://tasmeem.ma/blog/${article.title_fr.replace(/\s+/g, '%20')}/${article._id}`}
                                title={translate.language === "fr"?article.title_fr: article.title_en}
                                summary={translate.language === "fr"? article.title_fr + ", " + article.description_fr.substring(0,250): article.title_en + ", " +  article.description_en.substring(0,250)}
                                source="Tasmeem"
                            >
                                <img src={lkd} className='iconArticle' width="0.5em" height="0.5em" alt='linkedin share' style={{width:"0.5em", height:"0.5em", marginRight:"15px"}} />
                            </LinkedinShareButton>
                            
                        </Box>
                    </div>
                    <p className='article-time'>{`${date?.getDate()}-${("0" + (date.getMonth() + 1)).slice(-2)}-${date?.getFullYear().toString().substr(-2)}`}</p>
                    <Article article={article} />
                    <DisqusComments post={article} />
                </Grid>
            </Grid>
    </React.Fragment>
  ):null
}
