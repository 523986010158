import React, { Component } from 'react'

export default class Meetings extends Component {
  componentDidMount() {
  	const script = document.createElement('script');
    script.src = 'https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js';
    document.body.appendChild(script);
  }
  
	render() {
  	return (
    <div class="meetings-iframe-container" data-src="https://meetings-eu1.hubspot.com/yasmine?embed=true"></div>
    );
  }
}

