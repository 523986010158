import React from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles';
import SwipeableViews from 'react-swipeable-views';
import Pagination from '../paginations/Pagination';
import { autoPlay } from 'react-swipeable-views-utils';
import {Item} from './Item'
import './style.css'
import { useTranslation } from 'react-i18next';
import { findTestimonial } from '../../api/testimonial'

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);



const useStyles = makeStyles((theme) => ({
  root: {
    
    flexGrow: 1,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    height: 50,
    paddingLeft: theme.spacing(4),
    backgroundColor: theme.palette.background.default,
  },
  img: {
    height: 255,
    display: 'block',
    maxWidth: 400,
    overflow: 'hidden',
    width: '100%',
  },
}));

export const CarouselTestimonial = () => {
  const classes = useStyles();
  const [state, setState] = React.useState({index:0})
  const [testimonial, setTestimonial] = React.useState([])
  const [t, translate] = useTranslation('common');
  const { index } = state;

  React.useEffect(() => {
    findTestimonial()
    .then(response => {
      setTestimonial(response)
    })
  }, [])

    const handleChangeIndex = index => {
      setState({index: index});
    };
    return (
      <div className={classes.root}>
      <AutoPlaySwipeableViews
        index={index} 
        onChangeIndex={handleChangeIndex}
        autoplay={true}
        interval={10000}
      >
        {testimonial.map(e =>(
          <Item 
          image={e.photo.url}
          title={translate.language === 'fr'?e.title_fr:e.title_en} 
          paragraph={translate.language === 'fr'?e.description_fr:e.description_en}
          nom={e.nom_prenom}
          societe={e.societe}
          poste={translate.language === 'fr'?e.poste_fr:e.poste_en}
          />
        ))}
        
      </AutoPlaySwipeableViews>
      <Pagination dots={testimonial.length} index={index} onChangeIndex={handleChangeIndex} />
    </div>
    )
}
