import { Grid } from '@material-ui/core'
import {Card} from './Card'
import React from 'react'
import {TitleheaderLine} from '../texte'
import {BottomArow} from '../bouton'
import {useTranslation} from "react-i18next";
import { findServices } from '../../api/services'
const data =[
    {
        icon:"./assets/accueil/expertise/branding.png",
        title_fr:"Branding",
    },
    {
        icon:"./assets/accueil/expertise/packaging.png",
        title_fr:"Packaging",
    },
    {
        icon:"./assets/accueil/expertise/edition.png",
        title_fr:"Edition",
    },
    {
        icon:"./assets/accueil/expertise/digital.png",
        title_fr:"Digital",
    },
]

export const Expertise = () => {
    const [t, translate] = useTranslation('common');
    const [service, setService] = React.useState([])

    React.useEffect(() => {
        findServices()
        .then(response => {
        setService(response)
        })
    }, [])
    return (
        <Grid container justify="center" className="expertise-bloc" style={{paddingBottom:"100px"}} >
            <Grid item xs={11}>
                <Grid container justify="center">
                    <TitleheaderLine info={{title:t('accueil.expertise_title'), color:"jaune"}} textColor="#fff" />
                </Grid>
                <Grid container justify="center" spacing={2}>
                    {
                        service?.map(e => (
                            <Card service={e}/>
                        ))
                    }
                </Grid>
            </Grid>
            <BottomArow/>
        </Grid>
    )
}
