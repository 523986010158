import { Grid } from '@material-ui/core'
import React from 'react'
import {Title} from '../texte'
import {Bouton} from '../bouton'
import {useTranslation} from "react-i18next";

export const Contactez = () => {
    const [t, translate] = useTranslation('common');
    return (
        <Grid container justify="center" className="bloc-padding" style={{borderTop: 'solid #FFDA25 2px'}}>
            <Grid item xs={11}>
                <div style={{textAlign:'center', marginBottom:"50px"}}>
                    <Title txt={t('contactez_nous_section.title')} variant='h4' />
                </div>
                
                <Bouton txt={t('contactez_nous_section.bouton')} path='/contact' position='center' />
            </Grid>
        </Grid>
    )
}
