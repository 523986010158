import React from "react";
import "./index.css";
import App from "./App";
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import CloseIcon from "@material-ui/icons/Close";
import {
  createMuiTheme,
  responsiveFontSizes,
  ThemeProvider,
} from "@material-ui/core/styles";
import { SnackbarProvider } from "notistack";
import { Button } from "@material-ui/core";
import { findTranslate } from "./api/translate";
import { Loader } from "./components/Loader";
import { makeStyles } from "@material-ui/core/styles";
import { Worker } from "@react-pdf-viewer/core";
import { HelmetProvider } from "react-helmet-async";

let theme = createMuiTheme();
theme = responsiveFontSizes(theme);

export const Prov = () => {
  const [fr, setFr] = React.useState({});
  const [en, setEn] = React.useState({});
  const [loading, setLoading] = React.useState(true);
  const [lang, setlang] = React.useState("fr")
  React.useEffect(() => {
    const locale = navigator.language;
    let lang;
    if (locale==="en") {
      setlang("en")
    } else {
      setlang("fr")
    }

    findTranslate()
      .then((response) => {
        response.langues.map((langue) => {
          if (langue.code_langue === "fr") {
            setFr(langue);
          } else {
            setEn(langue);
          }
          setLoading(false);
        });
      })
      .catch((error) => {
        alert(error);
      });
  }, []);

  
  i18next.init({
    interpolation: { escapeValue: false }, // React already does escaping
    lng: lang, // language to use
    resources: {
      en: {
        common: en, // 'common' is our custom namespace
      },
      fr: {
        common: fr,
      },
    },
  });

  const notistackRef = React.createRef();
  const onClickDismiss = (key) => () => {
    notistackRef.current.closeSnackbar(key);
  };

  const useStyles = makeStyles((theme) => ({
    snackbar: {
      zIndex: 3000,
    },
  }));

  const classes = useStyles();

  return !loading ? (
    <React.StrictMode>
      <I18nextProvider i18n={i18next}>
        <ThemeProvider theme={theme}>
          <SnackbarProvider
            maxSnack={3}
            classes={{ containerRoot: classes.snackbar }}
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            ref={notistackRef}
            action={(key) => (
              <Button onClick={onClickDismiss(key)}>
                <CloseIcon style={{ color: "#fff" }} />
              </Button>
            )}
          >
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
              <HelmetProvider>
                <App />
              </HelmetProvider>
            </Worker>
          </SnackbarProvider>
        </ThemeProvider>
      </I18nextProvider>
    </React.StrictMode>
  ) : (
    <Loader />
  );
};
