import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import {Prov} from './Prov';
import reportWebVitals from './reportWebVitals';
import {I18nextProvider} from "react-i18next";
import i18next from "i18next";

import common_de from "./translations/de/common.json";
import common_en from "./translations/en/common.json";
import common_fr from "./translations/fr/common.json";
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

import {findTranslate} from './api/translate'
let fr = {}
let en = {}
findTranslate()
.then(response => {
  response.langues.map(langue => {
    if (langue.code_langue === 'fr'){
      fr = langue
    } else{
      en = langue
    }
  })
})
.catch(error => {
  alert(error)
})

i18next.init({
  interpolation: { escapeValue: false },  // React already does escaping
  lng: 'en',                              // language to use
        resources: {
            en: {
                common: en               // 'common' is our custom namespace
            },
            fr: {
              common: fr
          },
        },
});


ReactDOM.render(
  <Prov/>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
