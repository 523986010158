import { Box } from '@material-ui/core'
import {PhoneAndroid, MailOutline} from '@material-ui/icons'
import React from 'react'
import {Paragraph} from '../texte'
import {URL_API} from '../../config'
import './style.css'
import {useTranslation} from "react-i18next";


export const CardContact = ({contact}) => {
    const [t, translate] = useTranslation('common');

    return (
            <Box className='box-card-contact'>
                <Box display="flex" className="box-compress" justifyContent="space-around">
                    <Box>
                        <img alt="tasmeemien" style={{width:"140px"}} src={`${URL_API}${contact.image.url}`} />
                    </Box>
                    <Box className="card-contact-text">
                        <Paragraph className="paragraphe-name" txt={contact.display_name} gras style={{color:"#243C39"}} />
                        <Paragraph txt={contact.poste} gras style={{color:"#243C39", fontSize:"14px"}} />
                        <Box display="flex" className="box-card-icon">
                            <PhoneAndroid className='icon' />
                            <Paragraph txt={contact.tel} />
                        </Box>
                        <Box display="flex" className="box-card-icon">
                            <MailOutline className='icon' />
                            <Paragraph txt={contact.email} />
                        </Box>
                    </Box>
                </Box>
            </Box>
    )
}
