import { Hidden } from '@material-ui/core'
import React from 'react'
import { findpage } from '../api/page'
import { URL_API } from '../config'

export const Maintenance = () => {
    const [page, setPage] = React.useState(null)
    React.useEffect(() => {
        findpage('under_construction')
            .then(response => {
                setPage(response[0])
            })
            .catch(error => {
            })
    }, [])

    return page ? (
        <div className="bloc-padding" style={{ width: "90%", position: "relative", left: "50%", transform: "translateX(-50%)", textAlign: "center" }}>
            <Hidden only={["xs"]}>
                <img alt="" className="maintenance-img" style={{ position: "relative", maxWidth: "100%", width: "30%" }} src={URL_API + page.images[0].image.url} />
            </Hidden>
            <Hidden only={["lg", "md", "sm", "xl"]}>
                <img alt="" className="maintenance-img" style={{ position: "relative", maxWidth: "100%", width: "60%" }} src={URL_API + page.images[0].image.url} />
            </Hidden>
        </div>
    ) : null
}
