import React from 'react'
import { Box, Grid } from '@material-ui/core'
import { CardContact, FormContact } from '../components/contact'
import { findTasmemien } from '../api/tasmemien'
import { useTranslation } from "react-i18next";
import { TitleheaderLine } from '../components/texte'
import { Loader } from '../components/Loader';
import { MetaTags } from '../components/MetaTags';
import Meetings from '../components/Meetings';


export const Contact = ({rdv}) => {
    const [tasmemiens, setTasmemiens] = React.useState([])
    const [t,translate] = useTranslation('common');
    const [loading, setLoading] = React.useState(true)
    

    React.useEffect(() => {
        
        findTasmemien()
            .then(response => {
                setTasmemiens(response)
                setLoading(false)
            })
            .then(error => {
            })
    }, [])

    return !loading ? (
        <React.Fragment>
            <MetaTags
                meta={
                    {
                        title: translate.language === 'fr'?"Contactez-nous dès aujourd’hui! - Tasmeem":"Contact us today! - Tasmeem",
                        description: translate.language === 'fr'?"Obtenez de l'aide via le service client Tasmeem. Contactez-nous dès aujourd'hui pour discuter plus amplement de vos projets, de vos besoins et de votre vision. Nous restons à votre disponibilité et votre écoute pour toutes vos demandes et toutes vos questions.":"Get help through Tasmeem’s customer service. Contact us today to discuss your projects, your needs and your vision. We remain at your disposal for all your requests and questions.",
                        url: window.location.href,
                        image: "https://tasmeem.ma/assets/logo/logo.png",
                    }
                } />
            <Grid container justify="center" className="bloc-padding">
                <Grid item xs={10} lg={5}>
                    <TitleheaderLine info={{ title: t('contact.title_besoin_aide'), color: "jaune" }} width="20" align="left" textColor="#fff" />
                    <Box display="flex" flexDirection="column">
                        {
                            tasmemiens.map(t => (
                                <CardContact contact={t} />
                            ))
                        }
                    </Box>
                </Grid>
                <Grid item xs={10} lg={5}>
                    <TitleheaderLine info={{ title: t('contact.title_contactez_nous'), color: "jaune" }} align="left" textColor="#fff" />
                    <FormContact />
                </Grid>
            </Grid>
            <Grid container className="bloc-padding bloc-jaune" justify="center">
                <Grid id="rdv" item xs={11} lg={7}>
                    <TitleheaderLine info={{ title: t('contact.title_rdv'), color: "bleu" }} width={20} />
                    <Meetings />
                </Grid>
            </Grid>
        </React.Fragment>
    ) : (<Loader />)
}
