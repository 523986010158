import { Grid } from '@material-ui/core'
import React from 'react'
import {TitleheaderLine, Paragraph} from '../components/texte'
import {BoxQuiz} from '../components/quiz/BoxQuiz'
import {useTranslation} from "react-i18next";
import { MetaTags } from '../components/MetaTags';


export const Quiz = () => {
    const [t, translate] = useTranslation('common');
    return (
        <React.Fragment>
        <MetaTags
        meta={
            {
                title:translate.language === 'fr'?"Votre estimation gratuite en 48h! - Tasmeem":"Get a free estimate for your brand in 48 hours only! - Tasmeem", 
                description:translate.language === 'fr'?"Tasmeem vous propose un Quizz de moins de 5 min pour estimer vos besoins et vous aider à mieux définir une stratégie de communication pour votre entreprise. Répondez-y et recevez votre estimation en moins de 48 heures !":"Estimate your needs thanks to a 5 minutes quiz with Tasmeem today and define a communication strategy for your company. Receive your estimate in less than 48 hours and start building your brand!",
                url:window.location.href,
                image:"https://tasmeem.ma/assets/logo/logo.png",
            }
        } />
        <Grid container justify='center' className="bloc-padding" style={{textAlign:"center"}}>
            <Grid item xs={10} style={{color:"#fff", marginBottom:"50px"}}>
                <TitleheaderLine info={{title:t('quiz.title'), color:"jaune"}} textColor="#fff" width={20} variant='h2' />
                <Paragraph txt={`${t('quiz.description')} `} />
            </Grid>
            <Grid item xs={10}>
                <BoxQuiz />
            </Grid>
        </Grid>
       </React.Fragment> 
    )
}
