import { Button, FormControlLabel, Grid, Radio, RadioGroup } from '@material-ui/core'
import React from 'react'
import './style.css'
import emailjs from 'emailjs-com';
import { useSnackbar } from 'notistack'
import {useTranslation} from "react-i18next";

export const Devis = () => {
    const [t] = useTranslation('common');
    const [type, setType] = React.useState('Particulier')
    const [projet, setProjet] = React.useState({
        Digital:false,
        Branding:false,
        Packaging:false,
    })
    const [form, setForm] = React.useState({
        nom:"",
        societe:"",
        adresse:"",
        ville:"",
        email:"",
        tel:"",
        message:"",
        formType:"demande de devis"
    })
    const [inputRequired, setInputRequired] = React.useState({
        nom:false,
        email:false,
        tel:false
    })
    const { enqueueSnackbar } = useSnackbar()

    const handleSubmit = (event) => {
        event.preventDefault();

        let copy = {... form}
        copy['projet'] = ''
        for (const [key, value] of Object.entries(projet)) {
            if(value){
                copy['projet'] = copy['projet'] + `<p>${key}</p>`
            }
          }

        copy['html-content'] = `
        <table style="border-collapse: collapse; width: 80%; border-color: #dba720; margin-left: auto; margin-right: auto;" border="2">
            <tbody>
                <tr>
                    <td style="width: 49.7516%;"><h2>Type de client</h2></td>
                    <td style="width: 49.7527%;">${type}</td>
                </tr>
                <tr>
                    <td style="width: 49.7516%;"><h2>Nom</h2></td>
                    <td style="width: 49.7527%;">${copy.nom}</td>
                </tr>
                <tr>
                    <td style="width: 49.7516%;"><h2>Téléphone</h2></td>
                    <td style="width: 49.7527%;">${copy.tel}</td>
                </tr>
                <tr>
                    <td style="width: 49.7516%;"><h2>Adresse Email</h2></td>
                    <td style="width: 49.7527%;">${copy.email}</td>
                </tr>
                <tr>
                <td style="width: 49.7516%;"><h2>Sociéte</h2></td>
                    <td style="width: 49.7527%;">${copy.societe}</td>
                </tr>
                <tr>
                    <td style="width: 49.7516%;"><h2>Adresse</h2></td>
                    <td style="width: 49.7527%;">${copy.adresse} ${copy.ville}</td>
                </tr>
                <tr>
                    <td style="width: 49.7516%;"><h2>Type de projet</h2></td>
                    <td style="width: 49.7527%;">
                    ${copy.projet}
                    </td>
                </tr>
            </tbody>
        </table>
        <table style="border-collapse: collapse; width: 80%; border-color: #dba720; margin-left: auto; margin-right: auto;margin-top: 20px" border="2">
            <tr>
                <td style="width: 49.7527%;">
                    <h2>Message</h2>
                    <p>${copy.message}</p>
                </td>
            </tr>
        </table>
        `


        if(form.nom !== "" && form.email !== "" && form.tel !== "" ){
            emailjs.send("service_lklq3s7", "template_9lgivrj", copy, "user_bREg8PQQNXaLw6gdAVuP2")
            .then(function(response) {
                enqueueSnackbar("Opération réussie avec succès!",{
                    variant:"success",
                    persist: true,
                })
             }, function(error) {
                enqueueSnackbar("Opération échouée. Veuillez réessayer.",{
                    variant:"error",
                    persist: true,
                })
             });
        } else {
            let copy = {... inputRequired}
            if(form.nom === ""){
                copy.nom = true
                setInputRequired({... copy, nom:true})
            }
            if(form.email === ""){
                copy.email = true
                setInputRequired({... copy, email:true})
            }
            if(form.tel === ""){
                copy.tel = true
                setInputRequired({... copy, tel:true})
            }
        }
        
    }

    
    return (
        <div className="devis" id="devis">
            
            <Grid container justify="center" spacing={10}>
                
                <Grid item xs={12}>
                    <form onSubmit={handleSubmit}>
                    <div className="bloc-devis">
                        <label>{t('devis.label_vous_etes')}</label>
                        <div>
                        <RadioGroup style={{display:"none"}} aria-label="gender" name="type" value={type}>
                            <FormControlLabel value="Entreprise" control={<Radio />} />
                            <FormControlLabel value="Particulier" control={<Radio />} />
                        </RadioGroup>
                            <Button onClick={(event) => setType("Entreprise")} variant="contained" className={`${type === "Entreprise"?'button-devis':'button-devis-off'}`}>{t('devis.bouton_entreprise')}</Button>
                            <Button onClick={(event) => setType("Particulier")} variant="contained" className={`${type === "Particulier"?'button-devis':'button-devis-off'}`}>{t('devis.bouton_particulier')}</Button>
                        </div>
                        <label>{t('devis.label_votre_projet')}</label>
                        <div>
                            <Button onClick={(e) => setProjet({... projet, Digital: !projet.Digital})} variant="contained" className={`${projet.Digital?'button-devis':'button-devis-off'}`}>{t('devis.bouton_digital')}</Button>
                            <Button onClick={(e) => setProjet({... projet, Branding: !projet.Branding})} variant="contained" className={`${projet.Branding?'button-devis':'button-devis-off'}`}>{t('devis.bouton_branding')}</Button>
                        </div>
                        <div>
                            <Button onClick={(e) => setProjet({... projet, Packaging: !projet.Packaging})} variant="contained" className={`${projet.Packaging?'button-devis':'button-devis-off'}`}>{t('devis.bouton_packaging')}</Button>
                        </div>
                        <div>
                            <label>{t('devis.label_nom')}</label>
                            <div>
                                <input required={inputRequired.nom} placeholder={inputRequired.nom?"Veuillez remplir ce champs.":""} value={form.nom} onChange={(e) => {
                                    setForm({... form, nom: e.target.value});
                                    setInputRequired({... inputRequired, nom: false})
                                    }} 
                                    name="name" type='txt' /> 
                            </div>   
                        </div>
                        <div>
                            <label>{t('devis.label_societe')}</label>
                            <div>
                                <input value={form.societe} onChange={(e) => setForm({... form, societe: e.target.value})} name="ste" type='txt' /> 
                            </div>   
                        </div>
                        <div>
                            <label>{t('devis.label_adresse')}</label>
                            <div>
                                <input value={form.adresse} onChange={(e) => setForm({... form, adresse: e.target.value})} name="address" type='txt' /> 
                            </div>   
                        </div>
                        <div>
                            <label>{t('devis.label_ville')}</label>
                            <div>
                                <input value={form.ville} onChange={(e) => setForm({... form, ville: e.target.value})} name="ville" type='txt' /> 
                            </div>   
                        </div>
                        <div>
                            <label>{t('devis.label_email')}</label>
                            <div>
                                <input required={inputRequired.email} placeholder={inputRequired.email?"Veuillez remplir ce champs.":""} value={form.email} onChange={(e) => {
                                    setForm({... form, email: e.target.value});
                                    setInputRequired({... inputRequired, email: false})
                                }} 
                                name="e-mail" 
                                type='email' /> 
                            </div>   
                        </div>
                        <div>
                        <label>{t('devis.label_telephone')}</label>
                        <div>
                            <input required={inputRequired.tel} placeholder={inputRequired.tel?"Veuillez remplir ce champs.":""} value={form.tel} onChange={(e) => {
                                setForm({... form, tel: e.target.value});
                                setInputRequired({... inputRequired, tel: false})
                                }} 
                                name="tel"
                                 type='number' /> 
                        </div>   
                        </div>
                        <div>
                        <label>{t('devis.label_message')}</label>
                        <div>
                                <textarea onChange={(e) => {
                                    setForm({... form, message: e.target.value});
                                    }} 
                                    name="message"
                                     type='txt'></textarea>
                        </div>
                        </div>
                    <Button style={{float:"right"}} variant="contained" className="button-suivant" type="submit">{t('devis.bouton_envoyer')}</Button>
                    </div>
                </form>
                </Grid>
            </Grid>
            <button className="button-retour" onClick={(event) => document.getElementById('devis').style = 'width:0%;: 0px !important;'}><span>{t('devis.bouton_retour')}</span></button>
        </div>
    )
}
