import {URL_API} from '../config'
export const CreateNewsletter = (email) => {
    return new Promise((resolve, reject) => {
        fetch(`${URL_API}/newsletters`, {
            method: "POST",
            headers:{
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(email)
        })
        .then(response => response.json())
        .then(data => {  
            resolve(data)
        })
        .catch((error) => {
            reject(error)
        })
    })
}