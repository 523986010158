import { Grid } from '@material-ui/core'
import React from 'react'
import { BottomArow } from '../bouton'
import { Paragraph, Title } from '../texte'
import {URL_API} from '../../config'
import {useTranslation} from "react-i18next";

export const HeaderTwo = ({info, jaune, reverse, botomButton, style, bottom}) => {
    const j = jaune || false
    const direction = reverse || false
    const b = botomButton || false
    const [t, translate] = useTranslation('common');
    return (
        <Grid container justify="center" className={`quiNousSomme-bloc ${!bottom?"bloc-padding":null}  ${j?"bloc-jaune":null}`} direction={direction?'row-reverse':null} style={style}>
            <Grid item lg={6} style={{position:`${bottom?"relative":null}`}}>
                <img alt="tasmeem" src={`${URL_API}${info.image}`} width='100%' 
                style={{
                    position:`${bottom?'absolute':''}`,
                    right:`${bottom?'0':''}`,
                    bottom:`${bottom?'0':''}`
                }} 
                />
            </Grid>
            <Grid item xs={10} lg={6} className={`${bottom?"bloc-padding":null}`}>
                <div className="quiNousSomme-text">
                    <Title txt={info.title} />
                    <Paragraph txt={info.description} />
                </div>
            </Grid>
            {
                b?(
                  <BottomArow />  
                ):null
            }
            
        </Grid>
    )
}
