import { Grid, Typography } from '@material-ui/core'
import React from 'react'
import "animate.css/animate.min.css";
import ScrollAnimation from 'react-animate-on-scroll';


export const TitleheaderLine = ({info, width, variant, align, textColor, style, component}) => {
    const l = width || 50 
    const v = variant || "h4"
    const a = align || "center"
    const c = textColor || ""
    const comp = component || "p"
    const [w, setWidth] = React.useState(window.innerWidth);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    React.useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, [])

    let isMobile = w <= 768?true:false;
    return (
        <Grid item xs={12} justify="center" alignItems="center" className="titre-bloc" style={style}>
            <Typography style={{color:c}} align={`${isMobile?"center":a}`} variant={v} component={comp} className="titre">
                {info.title} 
            </Typography>
            <ScrollAnimation animateIn='animate__bounceIn'>
            <div className={`${isMobile?"center":a} ${info.color === 'jaune'?"divider-jaune":"divider-bleu"}`} style={{
                width:`${l}%`
                }}></div>
            </ScrollAnimation>
        </Grid>
    )
}
