import { Grid } from '@material-ui/core'
import React from 'react'
import { URL_API } from '../../config'
import {Paragraph, Title} from '../texte'
export const Item = ({image, title, paragraph, nom, societe, poste, }) => {
    return (
        <div>
            <Grid container justify="center">
            <Grid item xs={8} lg={3} className="carousel-box-image">
              <img alt="tasmeem testimonial" src={`${URL_API}${image}`} />
            </Grid>
            <Grid item xs={11} lg={9} className="carousel-box-text">
              <Title txt={title} variant='h4' />
              <Paragraph txt={paragraph} />
    <p className="testimonial-footer"><span className="testimonial-name">{nom} - {poste}</span> <br/> <span className="testimonial-agence">{societe}</span></p>
            </Grid>
            </Grid>
          </div>
          
    )
}
