import React from 'react'
import ReactCountryFlag from "react-country-flag"
import {useTranslation} from "react-i18next";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    flag : {
        position:'absolute',
        top:"-2px",
        right:"2vw",
        background: "#DBA720",
        borderRadius: '0px 0px 15px 15px',
        padding:'3px',
        paddingLeft:"8px",
        paddingRight:"8px",
        paddingBottom:"3px",
        display:'flex',
        justifyContent:'space-around',
        minWidth: '50px',
      },
      flagButton : {
        marginTop:"-3px",
        background:"none", 
        border:"none",
        cursor:"pointer",
        "&:focus":{
          outline: "none",
          border: "none"
        },
        "&:hover":{
          "& img":{
            filter:"drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.5))"
          }
        }
      }
  });

export const Flags = () => {
    const [t, translate] = useTranslation('common');
    const classes = useStyles();
    return (
        <div className={classes.flag}>
              <button className={classes.flagButton} onClick={() => translate.changeLanguage('fr')}>
                <ReactCountryFlag
                    countryCode="FR"
                    svg
                    style={{
                        width: '1.1em',
                        height: '1.1em',
                        borderRadius:"5px",
                    }}
                    title="FR"
                    alt="FR"
                    width='1.1em'
                    height='1.1em'
                />
              </button>
              <button className={classes.flagButton} onClick={() => translate.changeLanguage('en')}>
                <ReactCountryFlag
                    countryCode="GB"
                    svg
                    style={{
                        width: '1.1em',
                        height: '1.1em',
                        borderRadius:"5px",
                    }}
                    width='1.1em'
                    height='1.1em'
                    title="GB"
                    alt="EN"
                />
              </button>
            </div>
        
    )
}
