import React from 'react'
import { findProject } from '../../api/projects'
import { findServices } from '../../api/services'
import { useTranslation } from "react-i18next";
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { Grid, Hidden } from '@material-ui/core';
import './styles.css'
import { Projet } from './Projet';
import { Loader } from '../Loader';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (

        children
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: 500,
  },
}));

export const Projets = () => {
  const [projects, setProjects] = React.useState([])
  const [services, setServices] = React.useState([])
  const [t, translate] = useTranslation('common');
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const [loading, setLoading] = React.useState(true)

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  React.useEffect(() => {
    findServices()
      .then(response => {
        setServices(response)
        findProject()
          .then(response => {
            setProjects(response)
            setLoading(false)
          })
      })
  }, [])
  return !loading ? (
    <Grid container justify="center" >
      <Grid item xs={10}>
        <AppBar position="static" color="default" style={{ marginBottom: "30px" }}>
          <Hidden only={["xs"]}>
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
              variant="fullWidth"
              aria-label="full width tabs example"
            >
              {
                services.map((item, index) => (
                  <Tab label={`${translate.language === 'fr' ? item.title_fr : item.title_en}`} {...a11yProps(index)} />
                ))
              }
            </Tabs>
          </Hidden>
          <Hidden only={["sm", "md", "lg", "xl"]}>
            <Tabs
              orientation="vertical"
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
              variant="fullWidth"
              aria-label="full width tabs example"
            >
              {
                services.map((item, index) => (
                  <Tab label={`${translate.language === 'fr' ? item.title_fr : item.title_en}`} {...a11yProps(index)} />
                ))
              }
            </Tabs>
          </Hidden>

        </AppBar>
        <SwipeableViews
          axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
          index={value}
          onChangeIndex={handleChangeIndex}
        >
          {
            services.map((item, index) => (
              <TabPanel value={value} index={index} dir={theme.direction}>
                <Grid container>
                  {
                    projects.map(p => {
                      const html = p.services.map(e => {
                        if (e.title_fr === item.title_fr) {
                          return (
                            <Projet p={p} />
                          )
                        }
                      })
                      return html
                    })
                  }
                </Grid>
              </TabPanel>
            ))
          }
        </SwipeableViews>
      </Grid>
    </Grid>
  ) : (<Loader />)
}
