import { Box } from '@material-ui/core'
import React from 'react'
import {URL_API} from '../../config'
import './style.css'
import { useTranslation } from "react-i18next";
import { Paragraph } from '../texte';


export const Article = ({article}) => {
    const [t, translate] = useTranslation('common');
  return (
    <div className='article' style={{marginTop:"25px"}}>
        <img className='header-image' alt={translate.language === 'fr'?article.title_fr:article.title_en} width="100%" height="auto" src={URL_API + article.imageHeader.url} />
        <div className='description'>
            <Paragraph txt={translate.language === 'fr'?article.description_fr:article.description_en} />
        </div>


        {/* author */}
        <div className='section-author' style={{marginTop:"50px"}}>
            <img className='author-avatar' alt={article.author[0].name} width="auto" height="auto" src={URL_API + article.author[0].avatar.url} />
            <div className='d-flex flex-column'>
                <div className='d-flex justify-content-between'>
                    <div>
                        <p className='name'>{article.author[0].name}</p>
                        <p className='post'>{translate.language === 'fr'?article.author[0].poste_fr:article.author[0].poste_en}</p>
                    </div>
                    <Box display='flex' className="around  align-items-center">
                        {
                            article.author[0].social?.map(rc => (
                                <a href={rc.lien} target="_blank" rel="noreferrer" style={{marginRight:"15px"}} >
                                    <img alt={`${rc.nom}`} width="1.5em" height="1.5em" src={URL_API+rc.icon.url} className='iconArticle' style={{width:"1.5em", height:"1.5em"}} />  
                                </a>
                            ))
                        }
                    </Box>
                </div>
                <div className='description-author'>
                    <p>{translate.language === 'fr'?article.author[0].description_fr:article.author[0].description_en}</p>
                </div>
            </div>
        </div>
    </div>
  )
}
