import { Button } from '@material-ui/core'
import React from 'react'
import {Link} from 'react-router-dom'
import { useHistory } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';
import './style.css'


export const Bouton = ({txt, path, position, size, color, style}) => {
    // const linkTarget = {
    //     pathname: `${path}`,
    //     key: uuidv4(), // we could use Math.random, but that's not guaranteed unique.
    //     state: {
    //       applied: true
    //     }
    //   };
    const c = color || false
    let bg= ''
    let cc=''
    if(c){
        bg = 'linear-gradient(1.99deg, #243C39 0%, #25AE84 100%)'
        cc="#FFDA25"
    }
    return path?(
        <Link to={path} className={`${position} link`}>
        <Button type="button" variant="contained" className={`button`} style={{background:bg, color:cc, width:"max-content"}}>
            {txt}
        </Button>
        </Link>
    ):(
        <Button variant="contained" className={`button ${position}`} style={{background:bg, color:cc, width:"max-content"}}>
            {txt}
        </Button>
    )
}
