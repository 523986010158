import React from 'react'
import ReactCountryFlag from "react-country-flag"
import {useTranslation} from "react-i18next";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    flag : {
        position:'relative',
        borderRadius: '0px 0px 20px 20px',
        padding:'2px',
        paddingLeft:"50px",
        paddingRight:"15px",
        paddingBottom:"2px",
        minWidth: '70px',
      },
      flagButton : {
        background:"none", 
        border:"none",
        cursor:"pointer",
        marginRight:"20px",
        "&:focus":{
          outline: "none",
          border: "none"
        },
        "&:hover":{
          boxShadow:" 0px 4px 4px rgba(0, 0, 0, 0.25)",
        },
      }
  });

export const FlagsMobile = () => {
    const [t, translate] = useTranslation('common');
    const classes = useStyles();
    return (
        <div className={classes.flag}>
              <button className={classes.flagButton} onClick={() => translate.changeLanguage('fr')}>
                <ReactCountryFlag
                    countryCode="FR"
                    svg
                    style={{
                        width: '2em',
                        height: '2em',
                    }}
                    title="FR"
                />
              </button>
              <button className={classes.flagButton} onClick={() => translate.changeLanguage('en')}>
                <ReactCountryFlag
                    countryCode="GB"
                    svg
                    style={{
                        width: '2em',
                        height: '2em',
                    }}
                    title="GB"
                />
              </button>
            </div>
        
    )
}
