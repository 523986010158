import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import { useSpring, animated } from "react-spring/web.cjs"; // web.cjs is required for IE 11 support
import { Box, CircularProgress, Grid, Typography } from "@material-ui/core";
import { URL_API } from "../../config";
import SearchIcon from "@material-ui/icons/Search";

import CloseIcon from "@material-ui/icons/Close";
import { useTranslation } from "react-i18next";
// Import the main component
import { Viewer, SpecialZoomLevel } from "@react-pdf-viewer/core";
import ReactPlayer from "react-player/lazy";


// Import styles
import '@react-pdf-viewer/toolbar/lib/styles/index.css';

// Import the styles
import "@react-pdf-viewer/core/lib/styles/index.css";


const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: "2000 !important",
    border: "none",
  },
  paper: {
    padding: 0,
    position: "absolute",
    height: "95%",
    width: "80%",
    bottom: "0",
    left: "50%",
    transform: "translateX(-50%)",
    boxSizing: "border-box",
    overflow: "hidden",
  },
}));

const Fade = React.forwardRef(function Fade(props, ref) {
  const { in: open, children, onEnter, onExited, ...other } = props;
  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: open ? 1 : 0 },
    onStart: () => {
      if (open && onEnter) {
        onEnter();
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited();
      }
    },
  });

  return (
    <animated.div ref={ref} style={style} {...other}>
      {children}
    </animated.div>
  );
});

Fade.propTypes = {
  children: PropTypes.element,
  in: PropTypes.bool.isRequired,
  onEnter: PropTypes.func,
  onExited: PropTypes.func,
};

function CircularProgressWithLabel(props) {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress variant="determinate" style={{
          top: "50%",
          left: "50%",
          position: 'absolute',
          display: 'flex',
          width:"80px",
          height:"80px",
          transform:"translateX(-50%) translateY(-50%)",
          color:"#FFDA25"
      }} {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography variant="caption" component="p" color="text.secondary">
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}

CircularProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate variant.
   * Value between 0 and 100.
   * @default 0
   */
  value: PropTypes.number.isRequired,
};

export const Projet = (props) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [t, translate] = useTranslation("common");
  let { p } = props;


  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  React.useEffect(() => {}, []);

  return (
    <React.Fragment>
      <Grid
        item
        lg={3}
        md={6}
        xs={12}
        style={{ marginBottom: "10px", marginTop: "10px" }}
      >
        <div className="bloc-project" onClick={handleOpen}>
          <div
            style={{
              maxHeight: "120px",
              minHeight: "120px",
              height: "120px",
              position: "relative",
            }}
          >
            <img
            alt="tasmeem client"
              style={{
                position: "relative",
                width: "initial",
                top: "50%",
                transform: "translateY(-50%)",
              }}
              src={URL_API + p.icon_client?.url}
            />
          </div>
          <h5>
            {translate.language === "fr"
              ? p.title_projet_fr
              : p.title_projet_en}
          </h5>
          <p>
            {translate.language === "fr"
              ? p.description_projet_fr
              : p.description_projet_en}
          </p>
          <div className="hover-project">
            <SearchIcon />
          </div>
        </div>
      </Grid>
      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            {p.pdf && p.pdf.ext === ".pdf" ? (
              <React.Fragment>
              <Viewer
                className="iframe"
                fileUrl={URL_API + p.pdf?.url}
                defaultScale={SpecialZoomLevel.PageFit}
                renderLoader={(percentages: number) => (
                  <CircularProgressWithLabel value={percentages} />
                )}
              />
              </React.Fragment>
            ) : (
              <ReactPlayer
              className="video"
                controls
                playing
                url={URL_API + p.pdf?.url}
                width="100%"
                height="auto"
              />
            )}
          </div>
          <div className="close-button" onClick={handleClose}>
              <CloseIcon
                style={{ color: p.pdf?.ext !== ".pdf" ? "#fff" : "" }}
              />
            </div>
        </Fade>
      </Modal>
    </React.Fragment>
  );
};
