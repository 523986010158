import { Typography } from '@material-ui/core'
import React from 'react'
import marked from 'marked';
import './style.css'
export const Paragraph = ({className, txt, gras, style}) => {
    const g = gras || false
    const getMarkdownText = (t) =>{
        
        var rawMarkup = marked(t, {sanitize: true});
        
        var addAtribut = rawMarkup.replace(/<img/g, '<img width="auto" height="auto" style="max-width:100%; position:relative; left:50%; transform:translateX(-50%)"')


        var endunderline = addAtribut.replaceAll("&lt;/u&gt;", '</u>')
        var underline = endunderline.replaceAll("&lt;u&gt;", '<u>')
        
        console.log("uderline : " ,underline)
        return { __html: underline };
    }
    return (
        <>
        <Typography dangerouslySetInnerHTML={getMarkdownText(txt)} variant="div" paragraph={true} className={`${className} header-paragraph`} style={{... style, fontWeight:g?"bold":""}}>
                
        </Typography>
        </>
    )
}
