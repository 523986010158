import React from 'react'
import {Helmet} from "react-helmet-async";
export const MetaTags = ({meta}) => {
    return (
        <Helmet>
            <title>{meta.title}</title>
            <meta property="og:title" content={meta.title} />
            <meta name="description" content={meta.description}/>
            <meta name="og:description" content={meta.description}/>
            <meta property="og:url" content={meta.url} />
            <meta property="og:image" content={meta.image} />
            
            <meta name="og:email" content="contact@tasmeem.ma"/>
            <meta name="og:phone_number" content="0698-991795"/>
            
            <meta name="og:latitude" content="33.913478"/>
            <meta name="og:longitude" content="-6.917841"/>
            
            <meta name="author" content="Yasmine Chaouai"/>
            <meta property="og:type" content="website" />
            <link rel="canonical" href={meta.url} />
        </Helmet>
    )
}
