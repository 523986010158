import { Typography } from '@material-ui/core'
import React from 'react'
import './style.css'
export const Title = ({txt, variant, style}) => {
    const type = variant || 'h4'
    return (
        <Typography variant={type} component="h1" className="title" style={style}>
            {txt} 
        </Typography>
    )
}
