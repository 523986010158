import { Box, Grid, Hidden } from '@material-ui/core'
import React from 'react'
import './footer.css'
import {Paragraph, TitleheaderLine} from '../texte'
import {Bouton} from '../bouton'
import {Copyright} from './Copyright'
import {findLogo} from '../../api/Logo'
import {URL_API} from '../../config'
import {Link} from 'react-router-dom'
import {
    PhoneAndroid,
    MailOutline,
    Facebook,
    Instagram,
    LinkedIn,
    YouTube
} from '@material-ui/icons';
import {useTranslation} from "react-i18next";
import { HashLink } from 'react-router-hash-link';
import { findInformations } from '../../api/information'

export const Footer = () => {
    const [t] = useTranslation('common');
    const [logoFooter, setLogoFooter] = React.useState("")
    const [information, setInformation] = React.useState(null)
    React.useEffect(() => {
        findLogo()
        .then(response => {
          setLogoFooter(response.logo_footer.url)
        })
        .catch(error => {
          alert(error)
        })

        findInformations()
        .then(response =>{
            setInformation(response)
        })
        .catch(error =>{
            alert(error)
        })
      
    }, [])
    return information?(
        <footer>
            <Grid container justify="center" className='footer'>
                <Grid xs={11} >
                    <Grid container justify="center" spacing={5} >
                        <Grid className="logo-footer" item xs={11} sm={6} md={6} lg={4} direction="column" justify="flex-start" alignItems="center">
                            <Link to='/'>
                                <img alt="tasmeem logo" width="50%" height="auto" src={`${URL_API}${logoFooter}`} style={{marginBottom:"25px", width:"50%"}}></img>
                            </Link>
                            <Paragraph txt={t('footer.description')} />
                        </Grid>
                        <Grid item xs={11} sm={4} md={4} lg={2} direction="column" justify="flex-start" alignItems="flex-start">
                            <TitleheaderLine info={{title:t('footer.contact_title'), color:"jaune"}} width={20} variant="h6" align='left'/> 
                            <div style={{textAlign:"center !important"}}>
                            <Box display="flex">
                                <PhoneAndroid className='icon icon-info' />
                                <a href={`tel:${information.coordonnees?.tel}`}>
                                    <p className="info">{`(+212) ${information.coordonnees?.tel.split('+212')[1]}`}</p>
                                </a>
                            </Box>
                            <Box display="flex">
                                <MailOutline className='icon icon-info' />
                                <a href={`mailto:${information.coordonnees?.email}`}>
                                <p className="info">{information.coordonnees?.email}</p>
                                </a>
                            </Box>
                            </div>
                            {/*<Box display="flex">
                                <Home className='icon icon-info' />
                                <p className="info">Rien</p>
                            </Box>*/}
                        </Grid>
                        <Grid item xs={11} sm={4} md={4} lg={2}>
                            <TitleheaderLine info={{title:t('footer.social_title'), color:"jaune"}} width={20} variant="h6" align='left'/> 
                            <Box display='flex' className="around">
                            {
                                information.reseau_social?.map(rc => (
                                    <a href={rc.lien} target="_blank" rel="noreferrer" >
                                        <img alt={`${rc.nom} ${information.informations.raison_social}`} width="auto" height="auto" src={URL_API+rc.icon.url} className='icon' />  
                                    </a>
                                ))
                            }
                            </Box>
                        </Grid>
                        <Grid item xs={11} sm={6} md={6} lg={4}>
                            <TitleheaderLine info={{title:t('footer.rdv_title'), color:"jaune"}} width={20} variant="h6" align='left' style={{marginBottom:"0px"}} /> 
                            <HashLink to="/contact#rdv">
                            <Hidden only={["xs"]}>
                                <Bouton txt={t('footer.rdv_bouton')} position="left" style={{marginTop:"-25px"}}  />
                            </Hidden>
                            <Hidden only={["sm", "md", "lg", "xl"]}>
                                <Bouton txt={t('footer.rdv_bouton')} position="center" style={{marginTop:"-25px"}}  />
                            </Hidden>
                            </HashLink>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Copyright />
        </footer>
    ):null
}
