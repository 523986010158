import { Grid } from '@material-ui/core'
import React from 'react'
import {Title, Paragraph} from '../texte'
import {Bouton, BottomArow} from '../bouton'
// css
import "./header.css"
import {useTranslation} from "react-i18next";
import Hidden from '@material-ui/core/Hidden';

export const Header = ({img, title, paragraph, button, reverse, fullContent}) => {
    const visible = button || false
    const direction = reverse || false
    const [t] = useTranslation('common');
    return (
        <Grid container justify="center" className="header-bloc" direction={direction?'row-reverse':null} >
            <Grid xs={10} lg={fullContent?6:5}>
                <div className="header-text">
                    <Title txt={title} />
                    <Paragraph txt={paragraph} />
                    {visible?
                    <Bouton txt={t('accueil.header_bouton')} path='/Apropos' position='right'/>:
                    null
                    }
                    
                </div>
            </Grid>
            <Hidden only={['xs', 'sm', 'md']}>
                <Grid lg={fullContent?6:5}>
                    <img alt="tasmeem header" src={img} width="100%" />
                </Grid>
            </Hidden>
            {
                visible?
                <BottomArow/>
                :null
            }
            
        </Grid>
    )
}
