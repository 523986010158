import {URL_API} from '../config'
export const findPartenaire = () => {
    return new Promise((resolve, reject) => {
        fetch(`${URL_API}/partenaires`, {
            method: "GET",
            headers:{
                'Content-Type': 'application/json'
            }
        })
        .then(response => response.json())
        .then(data => {
            if(data){
                resolve(data)
            } else {
                reject("image introuvable")
            }
        })
        .catch((error) => {
            reject(error)
        })
    })
}