import React from 'react'
import {Header} from '../components/header/Header'
import {HeaderTwo} from '../components/header/HeaderTwo'
import {Piliers} from '../components/piliers/Piliers'
import {findpage} from '../api/page'

import { useHistory } from 'react-router-dom'
import {URL_API, URL} from '../config'
import {useTranslation} from "react-i18next";

import { Loader } from '../components/Loader'
import { MetaTags } from '../components/MetaTags'

export const Apropos = () => {
    const [infoPage, setInfoPage] = React.useState(null)
    const [images, setimages] = React.useState(null)
    const [piliers, setpiliers] = React.useState(null)
    const [t,translate] = useTranslation('common');
    const [loading, setLoading] = React.useState(true)

    React.useEffect(() => {
        findpage('about')
        .then(response =>{
            setInfoPage(response[0])
            setimages(response[0].images)
            setpiliers(response[0].piliers)
            const tt = response[0].images
            setLoading(false)
        })
        .catch(error =>{
        })
    }, [])

    return !loading?(
        <>
        <MetaTags
            meta={
                {
                    title:translate.language === 'fr'?"Notre histoire et notre valeur ajoutée - Tasmeem":"Our history and added value - Tasmeem", 
                    description:translate.language === 'fr'?"Tasmeem crée des expériences, un design et des communications qui façonnent chaque aspect des besoins d'une marque à travers 3 capacités de base: Stratégie de marque, packaging produit et présence digitale - que cela soit sur les réseaux sociaux ou à travers une présence web.":"We create experiences, designs and communications that shape every aspect of a brand's needs through 3 core services : Brand Strategy, Product Packaging, and Digital Presence - whether that be on social media or through a web presence.",
                    url:window.location.href,
                    image:"https://tasmeem.ma/assets/logo/logo.png",
                }
            } />
       {
           infoPage && images && piliers?(
            <>
            <Header 
            img={`${URL_API}${images.filter(i => i.name === "header")[0].image?images.filter(i => i.name === "header")[0].image.url:null}`} 
            title={t('about.header_title')} 
            paragraph={t('about.header_description')} 
            reverse 
            />
            <Piliers listPiliers={piliers} />
            <HeaderTwo 
            info={{title:t("about.excelence_title"), description:t("about.excelence_description"), image:images.filter(i => i.name === "content")[0].image?.url}} 
            reverse
            bottom
            style={{color:'#fff'}}
            />
            </>
           ): null
       }
       </>
    ):(<Loader />)
}
