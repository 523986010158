import { Box, Grid, Typography } from '@material-ui/core'
import React from 'react'
import {Bouton} from '../bouton'
import {useTranslation} from "react-i18next";
import { URL_API } from '../../config';

export const Card = ({service}) => {
    const [t, translate] = useTranslation('common');
    return (
        <Grid item xs={8} sm={8} md={6} lg={3} className="Expertise-card">
            <Box display='flex' flexDirection="column" className="expertise-Box">
                <Box>
                    <img alt="tasmeem icon" src={URL_API + service.icon_global.url} />
                </Box>
                <Box>
                    <Typography variant="h5" className="quiNousSomme-titre">
                        {translate.language === 'fr'?service.title_fr:service.title_en}
                    </Typography>
                </Box>
                <Box>
                    <Bouton txt={t('accueil.expertise_bouton')} path={`service/${service.title_fr}`} size={90} />
                </Box>
            </Box>
        </Grid>
    )
}
