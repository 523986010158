import { Grid } from '@material-ui/core'
import React from 'react'
import './style.css'
import {Title, Paragraph} from '../texte'
import {BottomArow} from '../bouton'
import {useTranslation} from "react-i18next";
import Hidden from '@material-ui/core/Hidden';

export const QuiNousSomme = ({image}) => {
    const [t, translate] = useTranslation('common');
    return (
        <Grid container justify="center" className="quiNousSomme-bloc bloc-jaune bloc-padding">
            <Hidden only={['xs', 'sm', 'md']}>
                <Grid item xs={10} lg={6} style={{textAlign:"center"}}>
                    <img alt="qui nous somme tasmeem" src="./assets/accueil/Group69.png" width='100%' />
                </Grid>
            </Hidden>
            <Grid item xs={10} lg={6}>
                <div className="quiNousSomme-text">
                    <Title txt={t('accueil.qui_nous_somme_title')} />
                    <Paragraph txt={t('accueil.qui_nous_somme_description')} />
                </div>
            </Grid>
            <BottomArow />
        </Grid>
    )
}
