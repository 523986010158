export const serviceData = {
    "Branding":{
        "title_fr":"Stratégie de marque - Tasmeem",
        "description_fr":"Tasmeem se charge de la conception de tous vos besoins en identité visuelle, du logotype à la charte graphique, en passant par toutes ses déclinaisons. Si vous avez une entreprise, vous avez des concurrents. Votre identité visuelle vous permet d’établir votre position sur le marché en vous différenciant des autres. Une identité visuelle réussie vous aidera à accroître votre notoriété sur le marché.",
        "title_en":"Brand strategy - Tasmeem",
        "description_en":"Tasmeem takes care of the design of all your visual identity needs, from the logo of your brand to it’s style guide, including all possible uses of it. If you have a company, you have competitors. Your visual identity allows you to establish your position on the market by differentiating you from others. A successful visual identity will help you increase your notoriety on the market."
    },
    "Digital":{
        "title_fr":"Présence digitale & audiovisuel - Tasmeem",
        "description_fr":"Il existe une puissance indéniable dans la sphère numérique. Les conceptions numériques excellent à briser les limites de l'espace et à susciter l'engagement actif des utilisateurs. Tasmeem fait en sorte que vous soyez visible et présent sur les supports digitaux de votre choix.",
        "title_en":"Digital & audiovisual presence - Tasmeem",
        "description_en":"There is an undeniable power in the digital sphere. Digital designs excel at breaking the boundaries of space and actively engaging users. Tasmeem makes sure that you are visible and present on the digital media of your choice, so that your brand doesn’t miss out on exponential growth in this sphere."
    },
    "Packaging":{
        "title_fr":"Packaging produit - Tasmeem",
        "description_fr":"L'emballage est le dernier message qu'un consommateur voit et une dernière chance de le convaincre d'acheter le produit. Pour que votre produit atteigne son objectif, vous devez penser à chaque détail de votre emballage, du matériau à la taille en passant par la disposition en rayon. Tasmeem s'occupe de la conception du packaging adéquat pour votre produit.",
        "title_en":"Product packaging - Tasmeem",
        "description_en":"Packaging is the last message a consumer sees and your last chance to convince them to buy your product. For your product to achieve its goal, you need to think about every detail of your packaging, from the material to the size to the shelf layout. Tasmeem takes care of designing the right packaging for your product according to your brand’s needs."
    }
}