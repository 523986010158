import React from 'react'
import './style.css'
import Hidden from '@material-ui/core/Hidden';

export const BottomArow = () => {
    const myRef = React.useRef(null)
    const executeScroll = () => myRef.current.scrollIntoView()  
    return (
        <Hidden only={['xs','sm']}s>
        <button ref={myRef} onClick={executeScroll} className="bottom-arrow">
            <img alt="tasmeem icon bottom arrow" src={`${process.env.PUBLIC_URL}/assets/icone/icon_bottom_arrow.png`} />
        </button>
        </Hidden>
    )
}
