import { Grid } from '@material-ui/core'
import {Link} from 'react-router-dom'
import React from 'react'
import './footer.css'
import {useTranslation} from "react-i18next";
import Hidden from '@material-ui/core/Hidden';

export const Copyright = () => {
    const [t] = useTranslation('common');
    return (
        <Grid container justify="center" className='copyright'>
            <Grid item xs={10}>
            <Hidden only={['xs','sm']}>
                <Grid container spacing={2} justify="center" style={{textAlign:"center"}}>
                    <Grid item lg={4}>
                        <Link to="/">
                            {
                            t('copyright.copyright')
                            }
                        </Link>
                    </Grid>
                    <Grid item lg={4}>
                    <Link to="/mentions-legales">
                    {
                        t('copyright.mentions_legales')
                    }
                    </Link>
                </Grid>
                </Grid>
            </Hidden>
            <Hidden only={['md','lg','xl']}>
                <Grid container spacing={2} justify="center" style={{textAlign:"center"}}>
                    <Grid item xs={7} lg={4}>
                        <Link to="/">
                            {
                            t('copyright.copyright')
                            }
                        </Link>
                    </Grid>
                    <Grid item xs={5} lg={4}>
                    <Link to="/mentions-legales">
                    {
                        t('copyright.mentions_legales')
                    }
                    </Link>
                </Grid>
                </Grid>
            </Hidden>
            </Grid>
        </Grid>
        
    )
}
