import { Button, CircularProgress, Grid, makeStyles, Typography } from '@material-ui/core'
import React from 'react'
import { MetaTags } from '../components/MetaTags'
import { useTranslation } from "react-i18next";
import { Paragraph, TitleheaderLine } from '../components/texte';
import { Bouton } from '../components/bouton';

// select
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { Link } from 'react-router-dom';
import { GetBlogs, GetCategories, GetLastArticles } from '../api/blogs';
import { CreateNewsletter } from '../api/newsletter'
import { URL_API } from '../config';
import { useSnackbar } from 'notistack'
import marked from 'marked';

const animatedComponents = makeAnimated();

const useStyles = makeStyles((theme) => ({
    selectRoot: {
        //...other styles
        '&:focus': {
            backgroundColor: 'yellow'
        }
    }
}));

const options = [
    { value: 'chocolate', label: 'Chocolate' },
    { value: 'strawberry', label: 'Strawberry' },
    { value: 'vanilla', label: 'Vanilla' }
]

export const Blog = () => {
    const [t, translate] = useTranslation('common');
    const classes = useStyles();

    const [newsletter, setNewsletter] = React.useState("")
    const [articles, setArticles] = React.useState(null)
    const [lastArticles, setLastArticles] = React.useState(null)
    const [categorie, setCategorie] = React.useState(null)
    const [start, setStart] = React.useState(0)
    const [filterContains, setFilterContains] = React.useState([])
    const { enqueueSnackbar } = useSnackbar()
    const [loader, setLoader] = React.useState(false)


    function ValidateEmail(mail) 
{
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail))
        {
            return (true)
        }
            enqueueSnackbar(translate.language === "fr"?"Vous avez saisi une adresse e-mail invalide !":"You have entered an invalid email address!",{
                variant:"warning"
            })
            return (false)
        }

    function disableBtn(mail) 
{
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail))
    {
        return (true)
    }
        // enqueueSnackbar(translate.language === "fr"?"Vous avez saisi une adresse e-mail invalide !":"You have entered an invalid email address!",{
        //     variant:"warning"
        // })
        return (false)
    }

    const AddNewslettter = (event) => {
        event.preventDefault();
        // create newsletter
        if(newsletter !== "" && ValidateEmail(newsletter)){
            CreateNewsletter({email:newsletter})
            .then(response => {
                enqueueSnackbar(translate.language === "fr"?"Merci de vous être inscrit à la Newsletter":"Thank you for subscribing to our Newsletter",{
                    variant:"success"
                })
                setNewsletter("")
            })
            .catch(error => {
                enqueueSnackbar(translate.language === "fr"?"Une erreur est survenue : "+ error:"An error has occurred : "+ error,{
                    variant:"error"
                })
            })
        }
    }

    const transformCatego = (data) => {
        const copy = []

        data.map(item => {
            copy.push({value: item._id, label: item.nom})
        })

        setCategorie(copy)
    }



    const handleInputChange = (newValue) => {
        console.log("filter change : ", newValue)
        let copy = newValue.map(e => e.value)
        setFilterContains(copy)

        let filter = ""
        copy.map(e => {
            filter = filter + `categories._id_in=${e}&`
        })

        
            filter = filter + `_start=0&_limit=5&_sort=published_at:DESC`
        console.log("filter : ", filter)
        GetBlogs(filter)
        .then(response => {
            setArticles(response)
        })
        .catch(error => {
            //error
        })

        setStart(0)
      };

      const loadMore = () => {
          setLoader(true)
        let filter = ""
        let st = start
        setStart(start+5)
        filterContains.map(e => {
            filter = filter + `categories._id_in=${e}&`
        })

        
            filter = filter + `_start=${st+5}&_limit=5&_sort=published_at:DESC`
            console.log("filter : ", filter)
        GetBlogs(filter)
        .then(response => {
            setArticles([... articles, ... response])
            setLoader(false)
        })
        .catch(error => {
            setLoader(false)
        })
      }


    React.useEffect(() => {
        GetCategories()
        .then(response => {
            let copy = []
            response.map(e => {
                if(e.blogs.length > 0){
                    copy.push(e)
                }
            })
            transformCatego(copy)
            setFilterContains([])
            // let filter = `${copy.map(e => `categorie._id_in=${e}&`)}`
            let filter =`_start=${start}&_limit=5&_sort=published_at:DESC`
            
            GetBlogs(filter)
            .then(response => {
                setArticles(response)
            })
            .catch(error => {
                //error
            })
        })
        .catch(error => {
            //error
        })

        GetLastArticles()
            .then(response => {
                setLastArticles(response[0])
            })
            .catch(error => {
                //error
            })


    }, [])

    const getMarkdownText = (t) =>{
        
        var rawMarkup = marked(t, {sanitize: true});

        const paragraphs = rawMarkup.split("</p>")
        const firstParagraph = paragraphs[0]+'</p>';
        

        // var underline = addAtribut.replace(/<ul/g, '<ul')
        return { __html: firstParagraph };
    }

    return (
        <React.Fragment>
            <MetaTags
                meta={
                    {
                        title: translate.language === 'fr' ? "Votre blog tech et design préféré" : "Your favorite tech and design blog",
                        description: translate.language === 'fr' ? "Votre blog tech et design préféré. Restez toujours à jour sur les dernières news et tendances de l’industrie du design et du digital en lisant nos articles." : "Your favorite tech and design blog. Always stay up to date on the latest news and trends in the design and digital industry by reading our articles.",
                        url: window.location.href,
                        image: "https://tasmeem.ma/assets/logo/logo.png",
                    }
                } />

            <Grid container justify="center" className="expertise-bloc bloc-padding">
                <Grid item xs={11} md={10} style={{ marginTop: "50px" }}>
                    <TitleheaderLine info={{ title: t('blog.page_title'), color: "jaune" }} width={20} variant="h3" textColor="#fff" />

                    <div>
                        <Typography variant="h1" className='desc'>
                            {t('blog.title_newslater')}
                        </Typography>
                        <form>
                        <div className='d-flex justify-content-center newsletter'>
                            <input type='mail' placeholder="..." value={newsletter} onChange={e => setNewsletter(e.target.value)} />
                            <button 
                                className={newsletter === "" || !disableBtn(newsletter)? "disable":null} 
                                disabled={newsletter === "" || !disableBtn(newsletter)?true:false} 
                                onClick={AddNewslettter}>{t('blog.bouton_newslater')}
                            </button>
                        </div>
                        </form>
                    </div>
                </Grid>
                {/* <!-- last Articles --> */}

                {
                    lastArticles ? (
                        <Grid item xs={11} md={10} style={{ marginTop: "70px" }}>
                            <div className='LastArticle' style={{ backgroundImage: `url(${URL_API + lastArticles.imageHeader.url})` }}>
                                <div className='filter-article'></div>
                                <div style={{ position: "relative", zIndex: "2" }}>
                                    <div className='header-lastArticle'>
                                        <h2>{translate.language === 'fr' ? lastArticles.title_fr : lastArticles.title_en}</h2>
                                        <div className='hashtag'>

                                        
                                        {
                                            lastArticles.categories.map(item => (
                                                <span>{item.nom}</span>
                                            ))
                                        }
                                        </div>
                                    </div>
                                    <div className='divider'></div>
                                    <div className='LastArticle-content'>
                                        <div dangerouslySetInnerHTML={translate.language === 'fr' ? getMarkdownText(lastArticles.description_fr) : getMarkdownText(lastArticles.description_en)}></div>
                                        <Link to={`blog/${lastArticles.title_fr}/${lastArticles._id}`} className='link'><button className='button'>{translate.language === 'fr' ? "Lire l'article" : "Read the article"}</button></Link>
                                    </div>
                                </div>

                            </div>
                        </Grid>
                    ) : null
                }



                {/* <!-- filter --> */}

                <Grid item xs={11} md={10} style={{ marginTop: "100px", zIndex: "2" }}>
                    <div className='d-flex article-filter' style={{ maxWidth: "1500px", left: "50%", transform: "translateX(-50%)", position: "relative" }}>
                        <div className='filter-child' style={{ width: "50%" }}>
                            <div>
                                <TitleheaderLine info={{ title: t('blog.filter_title'), color: "jaune" }} width={80} variant="h4" align={"left"} textColor="#fff" />
                            </div>
                        </div>
                        <div className='filter-child' style={{ width: "50%" }}>
                            {
                                categorie ? (
                                    <Select
                                        placeholder="Filtres.."
                                        className='multiSelect'
                                        classNamePrefix='multiSelect'
                                        closeMenuOnSelect={false}
                                        components={animatedComponents}
                                        isMulti
                                        options={categorie}
                                        onChange={handleInputChange}
                                    />
                                ) : null
                            }

                        </div>
                    </div>
                </Grid>

                {/* <!-- liste articles --> */}

                {
                    articles && articles.length > 0? articles.map(article => (
                        <Grid item xs={11} md={10} style={{ marginTop: "25px" }}>
                            <div className='d-flex justify-content-between list-article' style={{ maxWidth: "1500px", left: "50%", transform: "translateX(-50%)", position: "relative" }}>
                                <div className='image' style={{ backgroundImage: `url(${URL_API + article.imageHeader.url})` }}>
                                    {/* <img style={{ maxHeight: "340px" }} src={`${URL_API + article.imageHeader.url}`} /> */}
                                </div>
                                <div className='bloc-text'>
                                    <div className='d-flex justify-content-between flex-row-reverse marge header'>
                                        <div className='div-hastag-catego'>
                                        {
                                            article.categories.map(item => (
                                                <span className='hastag-catego'>{item.nom}</span>
                                            ))
                                        }
                                        </div>
                                        <h2 className='title'>{translate.language === 'fr' ? article.title_fr : article.title_en}</h2>
                                    </div>
                                    <div>
                                        <div className='description' dangerouslySetInnerHTML={translate.language === 'fr' ? getMarkdownText(article.description_fr) : getMarkdownText(article.description_en)}></div>
                                    </div>
                                    <Link to={`blog/${article.title_fr}/${article._id}`} className='link'><button className='button'>{translate.language === 'fr' ? "Lire l'article" : "Read the article"}</button></Link>
                                </div>

                            </div>
                        </Grid>
                    )):(
                        <Grid item xs={11} md={10} style={{ marginTop: "25px" }}>
                            <div className='d-flex justify-content-center list-article' style={{ maxWidth: "1500px", left: "50%", transform: "translateX(-50%)", position: "relative" }}>
                            <h2 className='title'>{t('blog.pas_d_article')}</h2>
                            </div>
                        </Grid>
                    )
                }


            </Grid>
            {
                articles && articles.length > 0?(
                <Grid container justify="center" style={{ marginBottom: "50px" }} >
                    {
                        !loader?(
                            <button style={{border:"none"}} className='button' onClick={loadMore}  >{translate.language === 'fr'? "Voir plus":"Load more"}</button>
                        ) : (
                            <CircularProgress style={{color:"#FFDA25"}} />
                        )
                    }
                </Grid> 
                ):null 
            }
            

        </React.Fragment>
    )
}
