import React from 'react'
import {Header} from '../components/header/Header'
import {QuiNousSomme, Expertise, Testimonial} from '../components/accueil/index'
import {useTranslation} from "react-i18next";
import { Partenaires } from '../components/partenaires/Partenaires'
import { Loader } from '../components/Loader';
import { MetaTags } from '../components/MetaTags'
export const Accueil = () => {
    const [t, translate] = useTranslation('common');
    const [loading, setLoading] = React.useState(true)

    React.useEffect(() => {
        setLoading(false)
    }, [loading])
    return !loading? (
        <React.Fragment>
        <MetaTags
            meta={
                {
                    title:translate.language === 'fr'?"Tasmeem - Service de design print & digital":"Tasmeem - Print & Digital design services", 
                    description:translate.language === 'fr'?"Tasmeem est un service axé sur le design print et digital qui est basée au Maroc. Nous créons pour vous un Branding clé et une stratégie de déploiement pour permettre à votre produit ou service d'être identifiable, promu et démarqué, que cela soit via une identité visuelle, une présence digitale ou au packaging produit. Nous comblons tous vos besoins en la matière avec une vision 360° de nos projets.":"Tasmeem - Print & Digital design services Tasmeem is a service focused on print and digital design, based in Morocco. We create for you a key branding and deployment strategy to allow your product or service to be identifiable, promoted and differentiated, whether it be through a visual identity, a digital presence or product packaging. We meet all your needs in this area thanks to a 360° vision of our projects.",
                    url:window.location.href,
                    image:"https://tasmeem.ma/assets/logo/logo.png",
                }
            } />
        <div>
            <Header img="assets/header/Group.png" title={t('accueil.header_title')} paragraph={t('accueil.header_description')} button fullContent/>
            <QuiNousSomme/>
            <Expertise/>
            <Testimonial/>
            <Partenaires />
        </div>
        </React.Fragment>
    ):(<Loader />)
}
